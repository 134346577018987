import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Invite } from "../dtos/invite.model";
import { EntityService } from "./entity-service";

export class CustomerInviteService extends EntityService<Invite> {
  baseUrl: string;
  constructor(companyId: number, customerId: number) {
    super();
    this.baseUrl = `companies/${companyId}/customers/${customerId}/invites`;
  }

  create(dto: Invite) {
    return apiRequest
      .post<ApiResponse<Invite>>(`${this.baseUrl}`, { ...new Invite(dto) })
      .then((o) => new Invite(o.data.data));
  }
}
