import React, { useState } from "react";
import { Button, LinkButton } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { License } from "../../../dtos/license.model";
import InfoList from "../../../common/info-list";
import { formattedDate } from "../../../helper";
import ConfirmationModal from "../../../common/confirmation-modal";
import useToastManager from "../../../hooks/toast-manager";
import { LicenseService } from "../../../services/license-service";
import BasicDetails from "../../../common/basic-details";

export function Component() {
  const navigate = useNavigate();
  const { successToast, errorToast } = useToastManager();
  const { licenseId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const licenseService = new LicenseService();

  const { data: license = {} as License } = useQuery({
    queryKey: ["get-license", licenseId],
    queryFn: () => licenseService.getOne(+licenseId),
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => licenseService.delete(+licenseId),
    onSuccess: () => {
      successToast(t`License was deleted successfully.`);
      navigate(`/manage/licenses`);
    },
    onError: () => {
      errorToast(t`Failed to delete license.`);
      setIsOpenModal(false);
    },
  });

  const onDelete = () => {
    setIsOpenModal(!isOpenModal);
  };

  const PANELS = [
    {
      header: t`License Info`,
      rows: [
        {
          label: t`Name`,
          value: license.name,
        },
        {
          label: t`Description`,
          value: license?.description,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Created`,
          value: license.createdBy + ", " + formattedDate(license.createdAt),
        },
        {
          label: t`Updated`,
          value: license.updatedBy + ", " + formattedDate(license.updatedAt),
        },
      ],
    },
  ];

  const goToLicenses = () => {
    navigate("/manage/licenses");
  };

  return (
    <>
      <BasicDetails
        title={license.name}
        navigateLabel={t`Back to Licenses`}
        onNavigateBack={goToLicenses}
      />
      {isOpenModal && (
        <ConfirmationModal
          icon="trash"
          onConfirm={mutateOnDelete}
          setShowModal={onDelete}
          message={`You're about to delete a ${license.name}. This Action cannot be undone.`}
        />
      )}
      <InfoList
        panels={PANELS}
        actions={
          <>
            <LinkButton
              icon="edit"
              iconPlacement="left"
              variant="secondary"
              to={`/manage/licenses/${licenseId}/edit/info`}
            >
              {t`Edit License Info`}
            </LinkButton>
            <Button
              icon="trash"
              iconPlacement="left"
              variant="secondary"
              onPress={onDelete}
              isDisabled={!license.canUnassign}
            >
              {t`Delete License`}
            </Button>
          </>
        }
      />
    </>
  );
}

export const licenseViewTabs = () => ({
  label: t`Licenses`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "permissions", label: t`Permissions` },
  ],
});

Component.displayName = "LicenseInfoView";
