import React from "react";
import { t } from "@lingui/macro";
import { StatusBadge as Badge } from "@fidelix/fx-miranda";

const StatusBadge = ({ active }: { active: boolean }) => {
  const variant = active ? "success" : "error";
  const statusText: string = active ? t`Active` : t`Deactivated`;

  return <Badge variant={variant}>{statusText}</Badge>;
};

export default StatusBadge;
