import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Role } from "../dtos/role.model";
import { EntityService } from "./entity-service";

interface IGetAllParams {
  page?: number;
  pageSize?: number;
  filter?: string;
  nameExact?: boolean;
  filterValues?: number[];
}

export class RoleService extends EntityService<Role> {
  constructor() {
    super();
  }
  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<Role[]>>(`manage/roles`, { params: { ...queries } })
      .then((o) => {
        return { ...o.data, data: o.data.data.map((role) => new Role(role)) };
      });
  }

  getOne(id: number, filters?: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<Role>>(`manage/roles/${id}`, { params: { ...filters } })
      .then((o) => new Role(o.data.data));
  }

  create(dto: Role): Promise<Role> {
    return apiRequest
      .post<ApiResponse<Role>>(`manage/roles`, { ...new Role(dto) })
      .then((o) => new Role(o.data.data));
  }

  update(id: number, dto: Role) {
    return apiRequest
      .put<ApiResponse<Role>>(`manage/roles/${id}`, { ...new Role(dto) })
      .then((o) => new Role(o.data.data));
  }

  delete(id: number) {
    return apiRequest.delete(`manage/roles/${id}`);
  }
}
