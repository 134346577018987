import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { licenseViewTabs } from "./tabs/view/info-view";

export const MANAGE_LICENSE_ROUTES = [
  {
    index: true,
    lazy: () => import("./license-list"),
  },
  {
    path: "new",
    lazy: () => import("./tabs/create"),
  },
  {
    path: ":licenseId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={licenseViewTabs} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/view/permissions-view"),
      },
    ],
  },
  {
    path: ":licenseId/edit*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/edit/info-edit"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/edit/permissions-edit"),
      },
    ],
  },
];
