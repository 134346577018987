import { Company } from "./company.model";
import { Entity } from "./entity.model";
import { Permission } from "./permission.model";
import { User } from "./user.model";

export class Role extends Entity {
  companyId: number | undefined;
  name: string;
  description: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  active: boolean;
  canDelete: boolean;
  company: Company;
  permissions: Permission[];
  users: User[];
  status: string;
  canEdit: boolean;
  isSystem: boolean;
  constructor({
    id,
    name,
    description,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    active,
    canEdit,
    canDelete,
    companyId,
    company,
    permissions,
    users,
    status,
    isSystem,
  }: Role) {
    super(id);
    this.name = name;
    this.description = description;
    this.createdAt = new Date(createdAt);
    this.createdBy = createdBy;
    this.updatedAt = new Date(updatedAt);
    this.updatedBy = updatedBy;
    this.active = active;
    this.canDelete = canDelete;
    this.canEdit = canEdit;
    this.companyId = companyId;
    this.company = company;
    this.permissions = permissions?.length > 0 ? permissions.map((m) => m) : [];
    this.users = users?.length > 0 ? users.map((m) => m) : [];
    this.status = status;
    this.isSystem = isSystem;
  }

  static isGlobal(data: Role) {
    return !data.companyId;
  }
}
