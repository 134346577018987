import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { apiKeyViewTabs } from "./tabs/view/info-view";

export const COMPANY_API_KEY_ROUTES = [
  { index: true, element: <Navigate to="active" replace /> },
  {
    path: "active",
    lazy: () => import("./key-list"),
  },
  {
    path: "deactive",
    lazy: () => import("./key-list"),
  },
  {
    path: "new",
    lazy: () => import("./tabs/create"),
  },
  {
    path: ":apiKeyId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={apiKeyViewTabs} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/view/permissions-view"),
      },
      {
        path: "device-rights",
        lazy: () => import("./tabs/view/device-rights-view"),
      },
    ],
  },
  {
    path: ":apiKeyId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        path: "info",
        lazy: () => import("./tabs/edit/info-edit"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/edit/permissions-edit"),
      },
      {
        path: "device-rights",
        lazy: () => import("./tabs/edit/device-righs-edit"),
      },
    ],
  },
];
