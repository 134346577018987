import { Role } from "../dtos/role.model";
import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { EntityService, IGetAllParams } from "./entity-service";

export class CompanyRoleService extends EntityService<Role> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `companies/${id}/roles`;
  }

  getAll(filters: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<Role[]>>(`${this.baseUrl}`, { params: { ...filters } })
      .then((o) => {
        return { ...o.data, data: o.data.data.map((role) => new Role(role)) };
      });
  }

  getOne(id: number) {
    return apiRequest
      .get<ApiResponse<Role>>(`${this.baseUrl}/${id}`)
      .then((o) => new Role(o.data.data));
  }

  create(dto: Role) {
    return apiRequest
      .post<ApiResponse<Role>>(`${this.baseUrl}`, { ...new Role(dto) })
      .then((response) => {
        return response.data.data;
      });
  }

  update(id: number, dto: Role) {
    return apiRequest
      .put<ApiResponse<Role>>(`${this.baseUrl}/${id}`, { ...new Role(dto) })
      .then((o) => new Role(o.data.data));
  }

  delete(id: number) {
    return apiRequest.delete(`${this.baseUrl}/${id}`);
  }
}
