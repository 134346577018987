import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import WithSideNavi from "../hoc/with-side-navi";
import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { companyTabViews } from "./tabs/view/info-view";
import { manageUserViews } from "./tabs/view/user-info-view";

export const MANAGE_COMPANY_ROUTES = [
  {
    index: true,
    element: <Navigate to="active" replace />,
  },
  {
    path: "active",
    lazy: () => import("./company-list"),
  },
  {
    path: "deactive",
    lazy: () => import("./company-list"),
  },
  {
    path: "new",
    lazy: async () =>
      import("./tabs/create").then(({ Component }) => ({
        Component: WithSideNavi(Component),
      })),
  },
  {
    path: ":companyId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={companyTabViews} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("./tabs/view/info-view"),
      },
      {
        path: "licenses",
        lazy: async () => import("./tabs/view/licenses-view"),
      },
      {
        path: "users",
        lazy: async () => import("./tabs/view/user-list-view"),
      },
    ],
  },
  {
    path: ":companyId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        path: "info",
        lazy: async () => import("./tabs/edit/info-edit"),
      },
      {
        path: "licenses",
        lazy: async () => import("./tabs/edit/licenses-edit"),
      },
    ],
  },
  {
    path: ":companyId/users/:userId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={manageUserViews} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("./tabs/view/user-info-view"),
      },
      {
        path: "roles",
        lazy: async () => import("./tabs/view/user-roles-view"),
      },
    ],
  },
  {
    path: ":companyId/users/:userId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("./tabs/edit/user-edit"),
      },
      {
        path: "roles",
        lazy: async () => import("./tabs/edit/user-roles-edit"),
      },
    ],
  },
];
