import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Company } from "../dtos/company.model";
import { EntityService } from "./entity-service";

interface IGetAllUserInvitesParams {
  page: number;
  pageSize: number;
  filter: string;
  sort?: string;
  order?: "asc" | "desc";
  nameExact?: boolean;
}

export class CustomerService extends EntityService<Company> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `companies/${id}/customers`;
  }

  create(dto: Company) {
    return apiRequest
      .post<ApiResponse<Company>>(`${this.baseUrl}`, { ...new Company(dto) })
      .then((o) => new Company(o.data.data));
  }

  getAll(queries: IGetAllUserInvitesParams) {
    return apiRequest
      .get<ApiResponse<Company[]>>(`${this.baseUrl}`, {
        params: { ...queries },
      })
      .then((o) => {
        return {
          ...o.data,
          data: o.data.data.map((company) => new Company(company)),
        };
      });
  }

  getOne(customerId: number) {
    return apiRequest
      .get<ApiResponse<Company>>(`${this.baseUrl}/${customerId}`)
      .then((o) => new Company(o.data.data));
  }

  update(customerId: number, dto: Company) {
    return apiRequest
      .put<ApiResponse<Company>>(`${this.baseUrl}/${customerId}`, {
        ...new Company(dto),
      })
      .then((o) => new Company(o.data.data));
  }

  delete(customerId: number) {
    return apiRequest.delete(`${this.baseUrl}/${customerId}`);
  }
}
