export enum Permission {
  COMPANY_ADMIN = "company.admin",
  SYSTEM_ADMIN = "system.admin",
}

export enum PERMISSION_TYPE {
  API_KEY = 1,
}

export enum USER_LANGUAGE {
  en = "English",
  fi = "Finnish",
}

export enum AUDIT_LOG_KEYS {
  firstName = "first name",
  lastName = "last name",
  email = "email",
  role = "role",
  roles = "roles",
  groups = "groups",
  active = "active",
  license = "license",
  apiKey = "API key",
  description = "description",
  permissionId = "permissionId",
  user = "user",
  users = "users",
  permissions = "permissions",
  name = "name",
}
