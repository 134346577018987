import { queryOptions, useQuery } from "@tanstack/react-query";
import { InternalCompaniesService } from "../services/internal-companies-service";

export const internalCompaniesQueries = {
  list: queryOptions({
    queryKey: ["internal-companies"],
    queryFn: () => InternalCompaniesService.getAll(),
  }),
};

export const useInternalCompaniesQuery = () => {
  return useQuery(internalCompaniesQueries.list);
};
