import React from "react";
import { Navigate } from "react-router-dom";

export const MANAGE_AUDIT_LOG_ROUTES = [
  {
    index: true,
    element: <Navigate to="user" replace />,
  },
  {
    path: "user",
    lazy: () => import("./view-logs"),
  },
  {
    path: "role",
    lazy: () => import("./view-logs"),
  },
  {
    path: "license",
    lazy: () => import("./view-logs"),
  },
  {
    path: "apiKey",
    lazy: () => import("./view-logs"),
  },
];
