import "react-toastify/dist/ReactToastify.css";
import "@fidelix/fx-miranda/base.css";
import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UikitProvider } from "@fidelix/fx-miranda";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { router } from "./router";
import { loadMessages } from "./i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

async function init() {
  // Load the default language messages for views that are not behind login.
  // We will load the user's language later after we have fetched the user.
  await loadMessages("en");

  const root = createRoot(document.getElementById("root") as HTMLElement);

  root.render(
    <QueryClientProvider client={queryClient}>
      <I18nProvider i18n={i18n}>
        <UikitProvider>
          <RouterProvider router={router} />
        </UikitProvider>
        <ToastContainer />
      </I18nProvider>
    </QueryClientProvider>,
  );
}

init();
