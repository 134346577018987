import { UserGroup } from "../dtos/user-group.model";
import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { EntityService, IGetAllParams } from "./entity-service";

export class CompanyUserGroupsService extends EntityService<UserGroup> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `companies/${id}/groups`;
  }

  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<UserGroup[]>>(`${this.baseUrl}`, {
        params: { ...queries },
      })
      .then((o) => {
        return {
          ...o.data,
          data: o.data.data.map((group) => new UserGroup(group)),
        };
      });
  }

  getOne(userId: number) {
    return apiRequest
      .get<ApiResponse<UserGroup>>(`${this.baseUrl}/${userId}`)
      .then((o) => new UserGroup(o.data.data));
  }

  create(dto: UserGroup) {
    return apiRequest
      .post<ApiResponse<UserGroup>>(`${this.baseUrl}`, {
        ...new UserGroup(dto),
      })
      .then((o) => new UserGroup(o.data.data));
  }

  update(userId: number, dto: UserGroup) {
    return apiRequest
      .put<ApiResponse<UserGroup>>(`${this.baseUrl}/${userId}`, {
        ...new UserGroup(dto),
      })
      .then((o) => new UserGroup(o.data.data));
  }

  delete(groupId: number) {
    return apiRequest.delete(`${this.baseUrl}/${groupId}`);
  }
}
