import React from "react";
import { t } from "@lingui/macro";
import { StatusBadge, Color } from "@fidelix/fx-miranda";
import styled from "styled-components";

const MultiStatusBadge = ({
  status,
  label,
}: {
  status: string;
  scale?: string;
  label?: string;
}) => {
  const statusMap: Record<string, { variant: Color; statusText: string }> = {
    Active: { variant: "success", statusText: t`Active` },
    Denied: { variant: "mutedDarkNeutral", statusText: t`Denied` },
    Expired: { variant: "mutedDarkNeutral", statusText: t`Expired` },
    Pending: {
      variant: "warning",
      statusText: t`Pending`,
    },
    Deactivated: { variant: "error", statusText: t`Deactivated` },
  };

  const { variant, statusText } = statusMap[status] || {
    variant: "mutedDarkNeutral",
    statusText: t`Unknown status`,
  };

  return (
    <StatusBadgeContainer>
      <StatusBadge variant={variant}>{label || statusText}</StatusBadge>
    </StatusBadgeContainer>
  );
};

export default MultiStatusBadge;

const StatusBadgeContainer = styled.div`
  display: flex;
`;
