/* eslint-disable @typescript-eslint/no-unused-vars */
import { USER_LANGUAGE } from "../constant";
import { Entity } from "./entity.model";
import { Invite } from "./invite.model";
import { Role } from "./role.model";
import { UserGroup } from "./user-group.model";
import { DeviceRight } from "./device-right.model";

export enum UserStatus {
  Pending = "Pending",
  Expired = "Expired",
  Active = "Active",
  Deactivated = "Deactivated",
}

export class User extends Entity {
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  lastLogin: string;
  active: boolean;
  permissions: string[];
  roles: Role[];
  deviceRights: DeviceRight[];
  groups: UserGroup[] | null;
  accepted: boolean;
  status: UserStatus;
  invites: Invite[];
  language: keyof typeof USER_LANGUAGE;

  constructor({
    email,
    firstName,
    lastName,
    createdAt,
    lastLogin,
    active,
    id,
    permissions,
    roles,
    deviceRights,
    groups,
    accepted,
    invites,
    language,
    status,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    lastLogin: string;
    active: boolean;
    id: number;
    permissions: string[];
    roles: Role[];
    deviceRights: DeviceRight[];
    groups: UserGroup[] | null;
    accepted: boolean;
    invites: Invite[];
    language: keyof typeof USER_LANGUAGE;
    status: UserStatus;
  }) {
    super(id);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.createdAt = createdAt;
    this.lastLogin = lastLogin;
    this.active = active;
    this.permissions = permissions;
    this.roles = (roles || []).map((role) => new Role(role));
    this.deviceRights = deviceRights || [];
    this.groups = groups
      ? groups.map((group) => new UserGroup(group || []))
      : null;
    this.invites = (invites || []).map((invite) => new Invite(invite));
    this.accepted = accepted;
    this.language = language;
    this.status = status;
  }

  static getStatus(current: {
    accepted: boolean;
    active: boolean;
    invite: Invite[];
  }): UserStatus {
    if (!current.accepted && current.active) {
      return current.invite.some(
        (invite) => new Date(invite.expiredAt).getTime() > new Date().getTime(),
      )
        ? UserStatus.Pending
        : UserStatus.Expired;
    }
    if (current.active) {
      return UserStatus.Active;
    }
    return UserStatus.Deactivated;
  }

  get fullName() {
    return `${this.firstName || ""} ${this.lastName || ""}`.trim();
  }
}
