import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { User } from "../dtos/user.model";
import { EntityService, IGetAllParams } from "./entity-service";

export class ManageCompanyUserService extends EntityService<User> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `manage/companies/${id}/users`;
  }

  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<User[]>>(`${this.baseUrl}`, { params: { ...queries } })
      .then((o) => {
        return {
          ...o.data,
          data: o.data.data.map((user) => new User(user)),
        };
      });
  }

  getOne(userId: number) {
    return apiRequest
      .get<ApiResponse<User>>(`${this.baseUrl}/${userId}`)
      .then((o) => new User(o.data.data));
  }

  update(userId: number, dto: User) {
    return apiRequest
      .put<ApiResponse<User>>(`${this.baseUrl}/${userId}`, { ...new User(dto) })
      .then((o) => new User(o.data.data));
  }

  delete(userId: number) {
    return apiRequest.delete(`${this.baseUrl}/${userId}`);
  }
}
