import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Invite } from "../dtos/invite.model";
import { EntityService } from "./entity-service";

export class ManageCompanyInviteService extends EntityService<Invite> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `manage/companies/${id}/invites`;
  }

  create(data: { email: string }) {
    return apiRequest
      .post<ApiResponse<Invite>>(`${this.baseUrl}`, data)
      .then((o) => new Invite(o.data.data));
  }
}
