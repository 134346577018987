import { Entity } from "./entity.model";
import { Permission } from "./permission.model";

export class License extends Entity {
  name: string;
  permissionId: string;
  description: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  permissions: Permission[];
  status: string;
  canUnassign: boolean;

  constructor({
    id,
    name,
    permissionId,
    description,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    permissions,
    status,
    canUnassign,
  }: License) {
    super(id);
    this.name = name;
    this.permissionId = permissionId;
    this.description = description;
    this.createdAt = new Date(createdAt);
    this.createdBy = createdBy;
    this.updatedAt = new Date(updatedAt);
    this.updatedBy = updatedBy;
    this.permissions = permissions?.length > 0 ? permissions.map((m) => m) : [];
    this.status = status;
    this.canUnassign = canUnassign;
  }
}
