import { useEffect, useState } from "react";
import { useMeQuery } from "../queries/me.queries";
import { loadMessages } from "../i18n";

export function useLoadMessages() {
  const { data: user, isFetched } = useMeQuery();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (user.language) {
      /**
       * In case loading the messages fails (eg. user language is invalid)
       * we still want to show the UI with the default language that is loaded
       * at the root of the app.
       */
      loadMessages(user.language).finally(() => {
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]); // only load messages once and not again in case user is refetched

  return loaded;
}
