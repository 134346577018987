import React from "react";
import styled from "styled-components";
import { Stack, Text } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";

interface IProps {
  panels: {
    header?: string;
    rows: {
      label: string;
      value: string | JSX.Element;
    }[];
  }[];
  actions?: JSX.Element;
}

const InfoList = ({ panels, actions }: IProps) => (
  <InfoCardContainer axis="y" spacing="medium">
    <Stack axis="x">
      {(panels || []).map((section, sectionIndex) => (
        <CardContentStack axis="y" spacing="none" key={sectionIndex}>
          <InfoTitle variant={"subtitle1Bold"}>
            {section.header ? section.header : <>&nbsp;</>}
          </InfoTitle>
          {section.rows.map((item, itemIndex) => (
            <Stack axis={"x"} key={itemIndex}>
              <LabelField variant={"bodyBold"} data-test-id="entity-label">
                {item.label}
              </LabelField>
              <ValueField
                variant="body"
                data-test-id={`entity-${item.label.toLowerCase()}`}
              >
                {item.value}
              </ValueField>
            </Stack>
          ))}
        </CardContentStack>
      ))}
    </Stack>
    {actions && (
      <ActionContainer axis="y" spacing="medium">
        <InfoTitle variant={"subtitle1Bold"}>{t`Actions`}</InfoTitle>
        <Stack axis={"x"} spacing={"medium"}>
          {actions}
        </Stack>
      </ActionContainer>
    )}
  </InfoCardContainer>
);

export default InfoList;

const InfoCardContainer = styled(Stack)`
  margin-top: ${(p) => p.theme.spacing.large}px;
`;

const ActionContainer = styled(Stack)`
  margin-top: ${(p) => p.theme.spacing.large}px;
`;

const InfoTitle = styled(Text)`
  margin-bottom: ${(p) => p.theme.spacing.medium}px;
`;

const CardContentStack = styled(Stack)`
  width: 50%;
`;

const LabelField = styled(Text)`
  padding: ${(p) => p.theme.spacing.small}px ${(p) => p.theme.spacing.none}px;
  width: 100%;
`;

const ValueField = styled(Text)`
  padding: ${(p) => p.theme.spacing.small}px ${(p) => p.theme.spacing.none}px;
  width: 100%;
`;
