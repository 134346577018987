import { t } from "@lingui/macro";
import { getPaths, upperLowerCase } from "../helper";
import { apiRequest } from "../auth/api-request";
import { ICrumb } from "../hooks/use-breadcrumbs";
import { ApiResponse } from "../dtos/api-respone.model";

const getViewCrumb = (
  url: string,
  label: string,
  id: string,
  baseUrl: string,
) => {
  const [root] = getPaths();
  const apiUrl = root === "manage" ? "manage/" : `companies/${root}/`;

  const getLabel = async () => {
    const data = await apiRequest
      .get<
        ApiResponse<{
          name: string;
          permissionId: string;
          domain: string;
          email: string;
          description: string;
        }>
      >(`${apiUrl}${url}/${id}`)
      .then((o) => o.data.data);

    const { name, permissionId, domain, email, description } = data;
    const labelValue =
      name || permissionId || domain || email || description || "";
    return labelValue;
  };

  return {
    id: `breadcrumb-${label}-view`,
    url: `/${baseUrl}/${url}/${id}`,
    getLabel,
  };
};

const getRootCrumb = (label: string, linkUrl: string, baseUrl: string) => {
  return {
    id: `breadcrumb-${label}`,
    url: `/${baseUrl}/${linkUrl || label}`,
    label: t`${upperLowerCase(label)}`,
  };
};

const getCrumb = (parts: string[], index: number, pathname: string) => {
  const part = parts[index];
  let currentPart: string;
  const [baseUrl] = pathname.split("/").filter(Boolean);

  switch (part) {
    case "customers":
    case "licenses":
    case "roles":
    case "permissions":
    case "companies":
    case "users":
    case "groups":
    case "invites":
    case "domains":
    case "system-admins":
    case "applications":
    case "api-keys":
      return getRootCrumb(part, parts.slice(0, index).join("/"), baseUrl);

    default: {
      currentPart = parts.slice(0, index).join("/");
      return getViewCrumb(currentPart, parts[index - 1], parts[index], baseUrl);
    }
  }
};

export const convertUrlToCrumbs = (pathname: string) => {
  const parts = pathname.split("/").filter(Boolean);
  const filterParts = parts.filter(
    (part) =>
      !["new", "edit", "info", "details", "device-rights"].includes(
        part.toLowerCase(),
      ),
  );
  const [, ...rest] = filterParts;
  const crumbs: ICrumb[] = [];
  for (let index = 0; index < rest.length; index++) {
    const crumb = getCrumb(rest, index, pathname);
    crumbs.push(crumb);
  }
  return crumbs;
};
