import React, { ReactNode } from "react";
import { Icon, Stack, Text } from "@fidelix/fx-miranda";
import { Button as AriaButton } from "react-aria-components";
import { t } from "@lingui/macro";
import styled from "styled-components";

type BasicDetailsProps = {
  title: string;
  description?: string | JSX.Element;
  navigateLabel?: string;
  onNavigateBack?: () => void;
  children?: ReactNode;
};
const BasicDetails = (props: BasicDetailsProps) => {
  const {
    title = "",
    description,
    navigateLabel,
    onNavigateBack,
    children,
  } = props;
  return (
    <BasicDetailContainer axis={"y"} spacing="medium">
      {onNavigateBack && (
        <NavigateButton onPress={onNavigateBack}>
          <Stack axis="x" align="center" width={"fit-content"} spacing="small">
            <Icon size={24} icon="arrowLeft" />
            <Text variant="body" color="darkNeutral">
              {navigateLabel || t`Back`}{" "}
            </Text>
          </Stack>
        </NavigateButton>
      )}
      <Text variant="title1Bold" color="extraDarkPrimary">
        {title}
      </Text>
      <Stack axis={"x"}>
        {description && <Text variant="body">{description}</Text>}
        {children}
      </Stack>
      <hr />
    </BasicDetailContainer>
  );
};

export default BasicDetails;

const BasicDetailContainer = styled(Stack)`
  border-bottom: 1px solid ${(p) => p.theme.colors.lightNeutral};
`;

const NavigateButton = styled(AriaButton)`
  background: ${(p) => p.theme.colors.white};
  border: ${(p) => p.theme.colors.white};
  cursor: pointer;
  width: fit-content;
  margin-bottom: ${(p) => p.theme.spacing.normal}px;
`;
