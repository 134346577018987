import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, LinkButton } from "@fidelix/fx-miranda";

import { CompanyRoleService } from "../../../services/company-roles-service";
import { EntityService } from "../../../services/entity-service";
import { Role } from "../../../dtos/role.model";
import useToastManager from "../../../hooks/toast-manager";
import { formattedDate } from "../../../helper";
import ConfirmationModal from "../../../common/confirmation-modal";
import InfoList from "../../../common/info-list";
import BasicDetails from "../../../common/basic-details";
import MultiStatusBadge from "../../../common/multi-status-badge";
import Loader from "../../../common/loader";

export function Component() {
  const navigate = useNavigate();
  const { roleId, companyId } = useParams();
  const { successToast, errorToast } = useToastManager();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const companyRoleService: EntityService<Role> = new CompanyRoleService(
    +companyId,
  );

  const { data: role = {} as Role, isLoading } = useQuery({
    queryKey: ["get-role", roleId],
    queryFn: () => companyRoleService.getOne(+roleId),
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => companyRoleService.delete(+roleId),
    onSuccess: () => {
      successToast(t`Role was deleted successfully.`);
      navigate(`/${companyId}/roles`);
    },
    onError: () => {
      errorToast(t`Failed to delete company role.`);
      setIsOpenModal(false);
    },
  });

  const PANELS = [
    {
      header: t`Role Info`,
      rows: [
        {
          label: t`Name`,
          value: role.name,
        },
        {
          label: t`Description`,
          value: role.description,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Owner`,
          value: role.company?.name || "System-managed",
        },
        {
          label: t`Created`,
          value: !role.isSystem
            ? role.createdBy + ", " + formattedDate(role.createdAt)
            : "",
        },
        {
          label: t`Updated`,
          value: !role.isSystem
            ? role.updatedBy + ", " + formattedDate(role.updatedAt)
            : "",
        },
      ],
    },
  ];

  const onDelete = () => {
    setIsOpenModal(!isOpenModal);
  };

  const goToRoles = () => {
    navigate(`/${companyId}/roles`);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {isOpenModal && (
        <ConfirmationModal
          icon="trash"
          onConfirm={mutateOnDelete}
          setShowModal={onDelete}
          message={`You're about to delete a ${role.name}. This Action cannot be undone.`}
        />
      )}
      <BasicDetails
        title={role.name}
        description={role.description}
        navigateLabel={t`Back to Roles`}
        onNavigateBack={goToRoles}
      >
        {<MultiStatusBadge status={role.status} scale="1.5" />}
      </BasicDetails>

      <InfoList
        panels={PANELS}
        actions={
          role.companyId === +companyId ? (
            <>
              <LinkButton
                icon="edit"
                iconPlacement="left"
                variant="secondary"
                to={`/${companyId}/roles/${roleId}/edit/info`}
                isDisabled={!role.canEdit}
              >
                {t`Edit Role Info`}
              </LinkButton>
              <Button
                icon="trash"
                iconPlacement="left"
                variant="secondary"
                onPress={onDelete}
                isDisabled={!role.canDelete}
              >
                {t`Delete Role`}
              </Button>
            </>
          ) : undefined
        }
      />
    </>
  );
}

export const roleTabViews = () => ({
  label: t`Role`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "permissions", label: t`Permissions` },
    { id: "users", label: t`Users` },
  ],
});

Component.displayName = "CompanyRoleInfoView";
