import React from "react";
import { useParams } from "react-router-dom";
import { t } from "@lingui/macro";

import UserInfoView from "../../../common/user-view";
import { CustomerUserService } from "../../../services/customer-users-service";
import { CustomerInviteService } from "../../../services/customer-invite-service";

export function Component() {
  const { customerId, userId, companyId } = useParams();
  const userService = new CustomerUserService(+companyId, +customerId);
  const inviteService: CustomerInviteService = new CustomerInviteService(
    +companyId,
    +customerId,
  );

  return (
    <UserInfoView
      id={userId}
      userService={userService}
      redirectUrl={`/${companyId}/customers/${customerId}/users`}
      deleteMessage={(email) =>
        t`You're about to remove ${email} from this customer. This action cannot be undone.`
      }
      inviteService={inviteService}
    />
  );
}

export const userTabViews = () => ({
  navList: [
    { id: "info", label: t`Info` },
    { id: "roles", label: t`Roles` },
  ],
});
Component.displayName = "CustomerUserView";
