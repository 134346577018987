import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import { Button, IconName, LinkButton, Stack } from "@fidelix/fx-miranda";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { formattedDate } from "../../../helper";
import { UserGroup } from "../../../dtos/user-group.model";
import { CompanyUserGroupsService } from "../../../services/company-user-groups-service";
import ConfirmationModal from "../../../common/confirmation-modal";
import InfoList from "../../../common/info-list";
import useToastManager from "../../../hooks/toast-manager";
import BasicDetails from "../../../common/basic-details";
import StatusBadge from "../../../common/status-badge";
import Loader from "../../../common/loader";

interface IConfirmationInfo {
  label: string;
  icon: IconName;
  buttonLabel: string;
}

export function Component() {
  const navigate = useNavigate();
  const { groupId, companyId } = useParams();
  const { successToast, errorToast } = useToastManager();
  const queryClient = useQueryClient();
  const [modal, setModal] = useState<boolean>(false);

  const userGroupsService = new CompanyUserGroupsService(+companyId);

  const { data: group = {} as UserGroup, isLoading } = useQuery({
    queryKey: ["get-group", groupId],
    queryFn: () => userGroupsService.getOne(+groupId),
  });

  const goBack = () => {
    navigate(`/${companyId}/groups`);
  };

  const { mutate } = useMutation({
    mutationFn: (data: UserGroup) => userGroupsService.update(+groupId, data),
    onSuccess: () => {
      successToast(
        t`Group ${group.active ? "deactivated" : "activated"} successfully.`,
      );
      queryClient.invalidateQueries({ queryKey: ["get-group"] });
      setModal(false);
    },
    onError: () => {
      errorToast(
        t`Failed to ${group.active ? "deactivated" : "activated"} group.`,
      );
      setModal(false);
    },
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => userGroupsService.delete(+groupId),
    onSuccess: () => {
      successToast(t`Group was deleted successfully.`);
      navigate(`/${companyId}/groups`);
    },
    onError: () => {
      errorToast(t`Failed to delete group.`);
      setModal(false);
    },
  });

  const PANELS = [
    {
      header: t`Group Info`,
      rows: [
        {
          label: "Name",
          value: group?.name,
        },
        {
          label: "Description",
          value: group?.description,
        },
        {
          label: "Owner",
          value: group?.company?.name,
        },
      ],
    },
    {
      rows: [
        {
          label: "Created",
          value: group?.createdBy + ", " + formattedDate(group?.createdAt),
        },
        {
          label: "Updated",
          value: group?.updatedBy + ", " + formattedDate(group?.updatedAt),
        },
      ],
    },
  ];

  const confirmationInfo = (row: UserGroup): IConfirmationInfo => {
    if (!row.canDelete && row.canEdit) {
      if (row.active) {
        return {
          icon: "deactive",
          buttonLabel: "Deactivate group",
          label: t`The roles added to the users via the group are removed.`,
        };
      }
      return {
        icon: "plus",
        buttonLabel: "Activate group",
        label: t`The roles added to the users via the group are removed.`,
      };
    }
    if (!row.canEdit) {
      return {
        icon: "trash",
        buttonLabel: "Remove group",
        label: t`You're about to remove ${row.name} from this company. This Action cannot be undone.`,
      };
    }
    return {
      icon: "trash",
      buttonLabel: "Delete group",
      label: t`You're about to delete a ${row.name}. This Action cannot be undone.`,
    };
  };

  const { label, icon, buttonLabel } = confirmationInfo(group);

  const onAction = () => {
    setModal(!modal);
  };

  const onStatusToggle = async () => {
    mutate(
      new UserGroup({
        ...group,
        roles: group.roles,
        active: !group.active,
      }),
    );
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {modal && (
        <ConfirmationModal
          icon={icon}
          onConfirm={
            (group.canDelete && group.active) || !group.canEdit
              ? mutateOnDelete
              : onStatusToggle
          }
          setShowModal={onAction}
          message={label}
        />
      )}
      <BasicDetails
        title={group.name}
        description={
          <Stack axis="x" align="center" spacing="large">
            {group.description && <span> {group.description} </span>}
            <StatusBadge active={group.active} />
          </Stack>
        }
        navigateLabel={t`Back to User Groups`}
        onNavigateBack={goBack}
      />
      <InfoList
        panels={PANELS}
        actions={
          <>
            {group.canEdit && (
              <LinkButton
                icon="edit"
                iconPlacement="left"
                variant="secondary"
                to={`/${companyId}/groups/${groupId}/edit/info`}
              >
                {t`Edit Group Info`}
              </LinkButton>
            )}
            {
              <Button
                icon={icon}
                iconPlacement="left"
                variant="secondary"
                onPress={onAction}
              >
                {buttonLabel}
              </Button>
            }
          </>
        }
      />
    </>
  );
}

export const groupTabViews = () => ({
  label: t`Groups`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "roles", label: t`Roles` },
    { id: "users", label: t`Users` },
    { id: "device-rights", label: t`Device Rights` },
  ],
});

Component.displayName = "GroupInfoView";
