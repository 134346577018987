import { useQueries } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { convertUrlToCrumbs } from "../common/breadcrumb-helper";

export interface ICrumb {
  id: string;
  url?: string;
  label?: string;
  getLabel?: () => Promise<string>;
}

function useBreadcrumbs() {
  const { pathname } = useLocation();
  const crumbs = convertUrlToCrumbs(pathname);

  const queries = useQueries({
    queries: crumbs.map((crumb: ICrumb) => ({
      queryKey: [`crumb`, crumb.id],
      queryFn: () => (crumb.getLabel ? crumb.getLabel() : crumb.label),
    })),
  });

  const isLoading = queries.some((query) => query.isLoading);

  const breadcrumbs = queries.map((query, index) => ({
    ...crumbs[index],
    label: query.data || "",
  }));

  return {
    breadcrumbs,
    isLoading,
    count: queries.length,
  };
}

export default useBreadcrumbs;
