import React, { useState } from "react";
import { t } from "@lingui/macro";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, IconName, Stack } from "@fidelix/fx-miranda";
import { useNavigate, useParams } from "react-router-dom";

import { Company } from "../../../dtos/company.model";
import { CustomerService } from "../../../services/customer-service";
import { formattedDate } from "../../../helper";
import StatusBadge from "../../../common/status-badge";
import BasicDetails from "../../../common/basic-details";
import InfoList from "../../../common/info-list";
import useToastManager from "../../../hooks/toast-manager";
import ConfirmationModal from "../../../common/confirmation-modal";
import Loader from "../../../common/loader";

interface IActionProps {
  icon: IconName;
  buttonLabel: string;
  message: string;
}

export function Component() {
  const navigate = useNavigate();
  const { companyId, customerId } = useParams();
  const { successToast, errorToast } = useToastManager();
  const [modal, setModal] = useState<JSX.Element | undefined>(undefined);
  const queryClient = useQueryClient();

  const customerService = new CustomerService(+companyId);

  const { data: customer = {} as Company, isLoading } = useQuery({
    queryKey: ["get-customer", customerId],
    queryFn: () => customerService.getOne(+customerId),
  });

  const { mutate: mutateOnEdit } = useMutation({
    mutationFn: (data: Company) => customerService.update(+customerId, data),
    onSuccess: () => {
      successToast(
        t`Customer ${
          customer.active ? "deactivated" : "activated"
        } successfully.`,
      );
      queryClient.invalidateQueries({ queryKey: ["get-customer"] });
      setModal(undefined);
    },
    onError: () => {
      errorToast(t`Failed to update customer.`);
      setModal(undefined);
    },
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => customerService.delete(customer.id),
    onSuccess: () => {
      navigate(`/${companyId}/customers`);
      successToast(t`Customer was deleted successfully.`);
    },
    onError: () => errorToast(t`Failed to delete customer.`),
  });

  const PANELS = [
    {
      header: t`Customer Info`,
      rows: [
        {
          label: t`Name`,
          value: customer?.name,
        },
        {
          label: t`Additional information`,
          value: customer?.additionalInfo,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Created`,
          value:
            customer?.createdBy + ", " + formattedDate(customer?.createdAt),
        },
        {
          label: `Updated`,
          value:
            customer?.updatedBy + ", " + formattedDate(customer?.updatedAt),
        },
      ],
    },
  ];

  const goBack = () => {
    navigate(`/${companyId}/customers`);
  };

  const onEdit = () => {
    navigate(`/${companyId}/customers/${customerId}/edit/info`);
  };

  const Status = () => {
    return (
      <Stack axis="x" align="center" spacing="large">
        {customer.additionalInfo && <span>{customer.additionalInfo}</span>}
        <StatusBadge active={customer.active} />
      </Stack>
    );
  };

  const onStatusToggle = async () => {
    if (customer.canDelete && customer.active) {
      mutateOnDelete();
    } else {
      mutateOnEdit(
        new Company({
          ...customer,
          isCustomer: !customer.isCompany(),
          active: !customer.active,
        }),
      );
    }
  };

  const onAction = () => {
    setModal((currentModal) =>
      currentModal ? undefined : (
        <ConfirmationModal
          onConfirm={onStatusToggle}
          setShowModal={onAction}
          message={message}
          icon={icon}
        />
      ),
    );
  };

  const confirmationInfo: () => IActionProps = () => {
    if (!customer.canDelete) {
      if (customer.active) {
        return {
          icon: "deactive",
          buttonLabel: t`Deactivate Customer`,
          message: t`When you deactivate a customer, the licenses granted to the customer are no longer valid, and users can no longer use the services authorized by the licenses.You can reactivate the customer at any time and enable the use of the services again.`,
        };
      }
      return {
        icon: "plus",
        buttonLabel: t`Activate Customer`,
        message: t`When you reactivate the company, the company's licenses come into effect again and users can use the services authorized by the licenses.`,
      };
    }
    return {
      icon: "trash",
      buttonLabel: t`Delete Customer`,
      message: t`You're about to delete a ${customer.name}. This Action cannot be undone.`,
    };
  };

  const { icon, buttonLabel, message }: IActionProps = confirmationInfo();

  if (isLoading) return <Loader />;

  return (
    <>
      {modal}
      <BasicDetails
        title={`${customer.name}`}
        description={<Status />}
        navigateLabel={t`Back to Customers`}
        onNavigateBack={goBack}
      />
      <InfoList
        panels={PANELS}
        actions={
          <>
            <Button
              icon="edit"
              iconPlacement="left"
              variant="secondary"
              onPress={onEdit}
            >
              {t`Edit Customer Info`}
            </Button>
            <Button
              icon={icon}
              iconPlacement="left"
              variant="secondary"
              onPress={onAction}
            >
              {buttonLabel}
            </Button>
          </>
        }
      />
    </>
  );
}

export const customersViews = () => ({
  label: t`Customer`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "licenses", label: t`Licenses` },
    { id: "groups", label: t`Groups` },
    { id: "users", label: t`Users` },
  ],
});

Component.displayName = "CustomerInfoView";
