import { Company } from "../dtos/company.model";
import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { EntityService } from "./entity-service";

interface IGetAllParams {
  page: number;
  pageSize: number;
  filter?: string;
  nameExact?: boolean;
}

export class CompaniesService extends EntityService<Company> {
  constructor() {
    super();
  }
  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<Company[]>>(`manage/companies`, {
        params: { ...queries },
      })
      .then((o) => {
        return {
          ...o.data,
          data: o.data.data.map((company) => new Company(company)),
        };
      });
  }

  getOne(id: number) {
    return apiRequest
      .get<ApiResponse<Company>>(`manage/companies/${id}`)
      .then((o) => new Company(o.data.data));
  }

  create(dto: Company) {
    return apiRequest
      .post<ApiResponse<Company>>(`manage/companies`, { ...new Company(dto) })
      .then((o) => new Company(o.data.data));
  }

  update(id: number, dto: Company) {
    return apiRequest
      .put<ApiResponse<Company>>(`manage/companies/${id}`, {
        ...new Company(dto),
      })
      .then((o) => new Company(o.data.data));
  }

  delete(id: number) {
    return apiRequest.delete(`manage/companies/${id}`);
  }
}
