import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Invite } from "../dtos/invite.model";
import { EntityService } from "./entity-service";

export class CompanyInviteService extends EntityService<Invite> {
  baseUrl: string;
  constructor(id: number) {
    super();
    this.baseUrl = `companies/${id}/invites`;
  }

  create(dto: Invite) {
    return apiRequest
      .post<ApiResponse<Invite>>(`${this.baseUrl}`, { ...new Invite(dto) })
      .then((response) => {
        return new Invite(response.data.data);
      });
  }
}
