import React from "react";
import { t } from "@lingui/macro";
import { css, styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarButton, Icon, Menu, Stack } from "@fidelix/fx-miranda";
import { type Key } from "react-aria-components";

import { redirectToLogout } from "../auth/auth.utils";
import { useMeQuery } from "../queries/me.queries";
import { useInternalCompaniesQuery } from "../queries/internal-companies.queries";
import { Permission } from "../constant";
import { hasPermission } from "../helper";

export default function ProfileMenu({
  name,
  id,
}: {
  name: string;
  id: string;
}) {
  const navigate = useNavigate();
  const { data: currentUser } = useMeQuery();
  const { data: companies = [] } = useInternalCompaniesQuery();

  const items: { url: string; label: string; name: string }[] = [];

  const isSystemAdmin = hasPermission(
    currentUser!.permissions,
    Permission.SYSTEM_ADMIN,
  );

  if (isSystemAdmin) {
    items.push({
      url: "/manage",
      label: t`Admin`,
      name: "Admin",
    });
  }
  /**
   * If user doesn't have any companies we don't allow them to navigate to
   * the "member" part of the app as they would just be automatically redirected
   * back to the "manage" (admin) part of the app.
   */
  if (companies.length > 1) {
    // TODO - create a common method to get/set items from/in session storage
    const companyId = sessionStorage.getItem("company-id") || "";

    items.unshift({
      url: companyId ? `/${companyId}/users` : "/",
      label: t`Member`,
      name: currentUser?.fullName || "--",
    });
  }

  const handleMenuAction = (id: Key) => {
    if (id === "logout") {
      redirectToLogout();
    } else if (id === "/me") {
      window.open(`${id}`, "_blank");
    } else {
      navigate(`${id}`);
    }
  };

  return (
    <MenuContainer
      onAction={handleMenuAction}
      trigger={<AvatarButton name={name} data-test-id="nav-menu-button" />}
    >
      <Menu.Section title="Role">
        {items.map((item, index) => {
          const { label, name, url } = item;
          return (
            <ProfileMenuItem
              key={index}
              url={url}
              name={name}
              label={label}
              isSelected={id === label}
            />
          );
        })}
      </Menu.Section>
      <Menu.Section title="Pages">
        <Menu.Item id="/me">
          <Stack axis="x" spacing="xsmall" align="center">
            <Icon icon="accountFilled" />
            <span>{t`My Account`}</span>
          </Stack>
        </Menu.Item>
      </Menu.Section>
      <Menu.Separator />
      <Menu.Item id="logout" data-test-id="nav-menu-item-logout">
        <Stack axis="x" spacing="xsmall" align="center">
          <Icon icon="logOut" key={"logOut"} />
          <span>{t`Log Out`}</span>
        </Stack>
      </Menu.Item>
    </MenuContainer>
  );
}

function ProfileMenuItem({
  label,
  url,
  isSelected,
  name,
}: {
  label: string;
  url: string;
  name: string;
  isSelected: boolean;
}) {
  return (
    <ProfileMenuItemWrapper id={url} $isSelected={isSelected}>
      <Stack axis="x" spacing="xsmall" align="center">
        <Avatar name={name} />
        <span>{label}</span>
      </Stack>
    </ProfileMenuItemWrapper>
  );
}

const ProfileMenuItemWrapper = styled(Menu.Item)<{ $isSelected: boolean }>`
  ${(p) =>
    p.$isSelected &&
    css`
      background-color: ${p.theme.colors.primary};
      color: ${p.theme.colors.white};

      &[data-hovered="true"] {
        background-color: ${p.theme.colors.primary};
      }
    `}
`;

const MenuContainer = styled(Menu)`
  margin-right: ${(p) => p.theme.spacing.medium}px;
  min-width: 300px;
`;
