import React from "react";
import WithSideNavi, { getNavInfo } from "../hoc/with-side-navi";

interface WithSideNaviWrapperProps {
  component: React.FC;
  navInfo?: getNavInfo;
}

const WithSideNaviWrapper: React.FC<WithSideNaviWrapperProps> = ({
  component: Component,
  navInfo,
}) => {
  const WrappedComponent = WithSideNavi(Component, navInfo);
  return <WrappedComponent />;
};

export default WithSideNaviWrapper;
