import React from "react";
import { Breadcrumbs } from "@fidelix/fx-miranda";
import useBreadcrumbs from "../hooks/use-breadcrumbs";

const Breadcrumb = () => {
  const { breadcrumbs, isLoading, count } = useBreadcrumbs();

  return (
    <>
      {isLoading ? (
        <Breadcrumbs.Loader numOfItems={count} />
      ) : (
        <Breadcrumbs>
          {breadcrumbs.map((crumb, index: number) => (
            <Breadcrumbs.Item key={index} to={crumb.url || ""}>
              {crumb.label}
            </Breadcrumbs.Item>
          ))}
        </Breadcrumbs>
      )}
    </>
  );
};

export default Breadcrumb;
