import React from "react";
import { Select } from "@fidelix/fx-miranda";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

import { useInternalCompaniesQuery } from "./queries/internal-companies.queries";

export default function CompanyManager() {
  const navigate = useNavigate();
  const { data: companies = [] } = useInternalCompaniesQuery();
  const { companyId } = useParams();

  const handleSelect = (value: string | number | null) => {
    if (value !== null) {
      navigate(`/${value}/users`);
    }
  };

  const selectWidth =
    companies.length > 0
      ? companies.find((c) => c.id === +companyId)?.name.length
      : undefined;

  return (
    <NavSelect
      isFilterable={true}
      data-test-id="company-select"
      aria-label="Companies"
      selected={companyId}
      onSelect={handleSelect}
      maxHeight="350px"
      $width={selectWidth ? selectWidth * 10 : 250}
      placeholder={
        companies.find((c) => c.id === Number(companyId))?.name || ""
      }
    >
      {companies.map((company) => (
        <Select.Option key={company.id} value={String(company.id)}>
          {company.name}
        </Select.Option>
      ))}
    </NavSelect>
  );
}

const NavSelect = styled(Select)<{ $width: number }>`
  min-width: 250px;
  width: ${(p) => p.$width}px;
  button {
    background-color: ${(p) => p.theme.colors.white};
  }
`;
