import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const SYSTEM_ADMIN_ROUTES = [
  {
    index: true,
    lazy: () => import("./admin-list"),
  },
  {
    path: ":userId/*",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "edit/info",
        lazy: () => import("./tabs/edit/info-edit"),
      },
    ],
  },
];
