import { AxiosResponse } from "axios";
import { ApiResponseMeta } from "../dtos/api-respone.model";
import { Entity } from "../dtos/entity.model";
import { UserStatus } from "../dtos/user.model";
import { AuditLogEntity } from "../helper";

interface IFilters {
  filter?: string;
  active?: boolean;
  expired?: boolean;
  status?: UserStatus;
}

export interface IGetAllParams extends IFilters {
  page?: number;
  pageSize?: number;
  isUnique?: boolean;
  includeParent?: boolean;
  excludeSystemPermissions?: boolean;
  email?: string;
  nameExact?: boolean;
  companyId?: number;
  filter?: string;
  filterValues?: number[];
  type?: number;
  isCustomer?: boolean;
  logEntity?: AuditLogEntity;
  sort?: string;
  order?: "asc" | "desc";
}

export class EntityService<T extends Entity> {
  getAll(
    queries: IGetAllParams,
  ): Promise<{ data: T[]; meta: ApiResponseMeta }> {
    throw new Error(`Not implemented for ${queries}`);
  }
  delete(id: number): Promise<AxiosResponse> {
    throw new Error(`Not implemented for ${id}`);
  }
  create(dto: Partial<T> | T[]): Promise<T> {
    throw new Error(`Not implemented for data ${dto}`);
  }
  getOne(id: number): Promise<T> {
    throw new Error(`Not implemented for ${id}`);
  }
  update(id: number, dto: T): Promise<T> {
    throw new Error(`Not implemented for  ${id} and ${dto}`);
  }
}
