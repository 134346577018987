import React from "react";
import styled from "styled-components";
import { Spinner } from "@fidelix/fx-miranda";

const Loader = () => {
  return (
    <LoaderContainer>
      <Spinner size="large" data-test-id="loader" />
    </LoaderContainer>
  );
};

export default Loader;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.spacing.xlarge}vh;
  justify-content: center;
`;
