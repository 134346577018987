import React from "react";
import { RouterProvider as AriaRouterProvider } from "react-aria-components";
import { Outlet, useNavigate } from "react-router-dom";
import { useLoadMessages } from "../hooks/use-load-messages";

export function RootRoute() {
  const navigate = useNavigate();
  const hasLoadedMessages = useLoadMessages();
  if (!hasLoadedMessages) return null;

  return (
    <AriaRouterProvider navigate={navigate}>
      <Outlet />
    </AriaRouterProvider>
  );
}
