import React from "react";
import { t } from "@lingui/macro";
import { useParams } from "react-router-dom";

import UserInfoView from "../../../common/user-view";
import { ManageCompanyUserService } from "../../../services/manage-company-user-service";
import { ManageCompanyInviteService } from "../../../services/manage-company-invites-service";

export function Component() {
  const { userId, companyId } = useParams();
  const userService = new ManageCompanyUserService(+companyId);
  const inviteService: ManageCompanyInviteService =
    new ManageCompanyInviteService(+companyId);

  return (
    <UserInfoView
      id={userId}
      userService={userService}
      redirectUrl={`/manage/companies/${companyId}/users`}
      deleteMessage={(email) =>
        t`You're about to remove ${email} from this company. This action cannot be undone.`
      }
      inviteService={inviteService}
    />
  );
}

export const manageUserViews = () => ({
  navList: [
    { id: "info", label: t`Info` },
    { id: "roles", label: t`Roles` },
  ],
});

Component.displayName = "ManageCompanyUserInfoView";
