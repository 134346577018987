import { ToastOptions, toast } from "react-toastify";

const useToastManager = () => {
  const configuration: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const successToast = (message: string) => {
    toast.success(message, configuration);
  };

  const errorToast = (message: string) => {
    toast.error(message, configuration);
  };

  const warningToast = (message: string) => {
    toast.warning(message, configuration);
  };

  return {
    successToast,
    errorToast,
    warningToast,
  };
};

export default useToastManager;
