import React from "react";
import { Navigate } from "react-router-dom";

export const ACCOUNT_ROUTES = [
  {
    index: true,
    element: <Navigate to="details" replace />,
  },
  {
    path: "details/",
    lazy: () => import("./tabs/view/info-view"),
  },
  {
    path: "details/edit/*",
    lazy: () => import("./tabs/edit/info-edit"),
  },
];
