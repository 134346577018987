import { apiRequest } from "../auth/api-request";
import { ApiKey } from "../dtos/api-key.model";
import { ApiResponse } from "../dtos/api-respone.model";
import { EntityService, IGetAllParams } from "./entity-service";

export class ApiKeyService extends EntityService<ApiKey> {
  baseUrl: string;
  constructor(companyId: number) {
    super();
    this.baseUrl = `companies/${companyId}/api-keys`;
  }
  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<ApiKey[]>>(`${this.baseUrl}`, { params: { ...queries } })
      .then((o) => ({
        ...o.data,
        data: o.data.data.map((key) => new ApiKey(key)),
      }));
  }

  getOne(id: number) {
    return apiRequest
      .get<ApiResponse<ApiKey>>(`${this.baseUrl}/${id}`)
      .then((o) => new ApiKey(o.data.data));
  }

  create(dto: ApiKey) {
    return apiRequest
      .post<ApiResponse<ApiKey>>(`${this.baseUrl}`, { ...new ApiKey(dto) })
      .then((response) => {
        return new ApiKey(response.data.data);
      });
  }

  update(id: number, dto: ApiKey) {
    return apiRequest
      .put<ApiResponse<ApiKey>>(`${this.baseUrl}/${id}`, { ...new ApiKey(dto) })
      .then((o) => new ApiKey(o.data.data));
  }

  delete(id: number) {
    return apiRequest.delete(`${this.baseUrl}/${id}`);
  }
}
