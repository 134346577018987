import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { styled } from "styled-components";
import { Trans } from "@lingui/macro";
import { Button, Stack, Text } from "@fidelix/fx-miranda";

export function RootRouteError() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);

  return (
    <Wrapper>
      <Stack axis="y" spacing="medium" align="center">
        <Text variant="title1">
          <Trans>Something went wrong</Trans>
        </Text>
        <Text variant="body">
          <Trans>
            An unexpected error occured. Please try to reload the page or
            contact support.
          </Trans>
        </Text>
        <Button onPress={() => window.location.reload()}>Reload page</Button>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
