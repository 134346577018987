import { Entity } from "./entity.model";
import { UserGroup } from "./user-group.model";
import { License } from "./license.model";

enum CompanyType {
  Company,
  Customer,
}

export class Company extends Entity {
  name: string;
  additionalInfo: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  licenses: License[];
  active: boolean;
  canDelete: boolean;
  type: CompanyType;
  groups: UserGroup[];
  parentId: number;

  constructor({
    id,
    name,
    additionalInfo,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    licenses,
    active,
    canDelete,
    isCustomer,
    groups,
    parentId,
  }: {
    id: number;
    name: string;
    additionalInfo: string;
    createdAt: string | Date;
    createdBy: string;
    updatedAt: string | Date;
    updatedBy: string;
    licenses: License[];
    active: boolean;
    canDelete: boolean;
    isCustomer?: boolean;
    groups?: UserGroup[];
    parentId: number;
  }) {
    super(id);
    this.name = name;
    this.additionalInfo = additionalInfo;
    this.createdAt = new Date(createdAt);
    this.createdBy = createdBy;
    this.updatedAt = new Date(updatedAt);
    this.updatedBy = updatedBy;
    this.licenses = (licenses || []).map((license) => new License(license));
    this.active = active;
    this.canDelete = canDelete;
    this.type = isCustomer ? CompanyType.Customer : CompanyType.Company;
    this.groups = (groups || []).map((group) => new UserGroup(group));
    this.parentId = parentId;
  }

  isCompany() {
    return this.type === CompanyType.Company;
  }
}
