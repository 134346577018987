import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import WithSideNaviWrapper from "../router/with-side-navi-wrapper";

export const PERMISSION_ROUTES = [
  {
    index: true,
    lazy: () => import("./permission-list"),
  },
  {
    path: "new",
    lazy: () => import("./tabs/create"),
  },
  {
    path: ":id",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "edit",
        lazy: () => import("./tabs/edit/info-edit"),
      },
    ],
  },
];
