import { DeviceRight } from "./device-right.model";
import { Entity } from "./entity.model";
import { Permission } from "./permission.model";

export class ApiKey extends Entity {
  key: string;
  description: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string | null;
  active: boolean;
  permissions: Permission[];
  deviceRights: DeviceRight[];

  constructor({
    id,
    key,
    description,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    active,
    permissions,
    deviceRights,
  }: ApiKey) {
    super(id);
    this.key = key;
    this.description = description;
    this.createdAt = new Date(createdAt);
    this.createdBy = createdBy;
    this.updatedAt = new Date(updatedAt);
    this.updatedBy = updatedBy;
    this.active = active;
    this.permissions = permissions || [];
    this.deviceRights = deviceRights || [];
  }
}
