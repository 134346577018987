import React, { useState } from "react";
import { t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Button, IconName, LinkButton, Stack } from "@fidelix/fx-miranda";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import InfoList from "../../../common/info-list";
import { formattedDate } from "../../../helper";
import { Company } from "../../../dtos/company.model";
import ConfirmationModal from "../../../common/confirmation-modal";
import { CompaniesService } from "../../../services/company-service";
import useToastManager from "../../../hooks/toast-manager";
import StatusBadge from "../../../common/status-badge";
import BasicDetails from "../../../common/basic-details";
import Loader from "../../../common/loader";

interface IConfirmationInfo {
  label: string;
  icon: IconName;
  buttonLabel: string;
}

export function Component() {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { successToast, errorToast } = useToastManager();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const companiesService = new CompaniesService();
  const queryClient = useQueryClient();

  const { data: company = {} as Company, isLoading } = useQuery({
    queryKey: ["get-company", companyId],
    queryFn: () => companiesService.getOne(+companyId),
  });

  const goToCompanies = () => {
    navigate("/manage/companies");
  };

  const Status = () => {
    return (
      <Stack axis="x" align="center" spacing="large">
        <span>{company.additionalInfo || "-"}</span>
        <StatusBadge active={company.active} />
      </Stack>
    );
  };

  const { mutate } = useMutation({
    mutationFn: (company: Company) =>
      companiesService.update(+companyId, company),
    onSuccess: (company) => {
      queryClient.invalidateQueries({ queryKey: ["get-company"] });
      successToast(
        t`Company ${
          company.active ? "activated" : "deactivated"
        } successfully.`,
      );
      setIsOpenModal(false);
    },
    onError: () => {
      errorToast(
        t`Failed to ${company?.active ? "deactivate" : "activate"} company.`,
      );
      setIsOpenModal(false);
    },
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => companiesService.delete(+company.id),
    onSuccess: () => {
      successToast(t`Company was deleted successfully.`);
      navigate("/manage/companies");
    },
    onError: () => {
      errorToast(t`Failed to delete company.`);
    },
  });

  const PANELS = [
    {
      header: "Company Info",
      rows: [
        {
          label: "Name",
          value: company.name,
        },
        {
          label: "Additional information",
          value: company?.additionalInfo,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Created`,
          value: company.createdBy + ", " + formattedDate(company.createdAt),
        },
        {
          label: t`Updated`,
          value: company.updatedBy + ", " + formattedDate(company.updatedAt),
        },
      ],
    },
  ];

  const confirmationInfo = (row: Company): IConfirmationInfo => {
    if (!row.canDelete) {
      if (row.active) {
        return {
          icon: "deactive",
          buttonLabel: "Deactivate Company",
          label: t`When you deactivate a company, the licenses granted to the company and its customers are no longer valid, and users can no longer use the services authorized by the licenses. You can reactivate the company at any time and enable the use of the services again.`,
        };
      }
      return {
        icon: "plus",
        buttonLabel: "Activate Company",
        label: t`When you reactivate the company, the company's licenses come into effect again and users can use the services authorized by the licenses.`,
      };
    }
    return {
      icon: "trash",
      buttonLabel: "Delete Company",
      label: t`You're about to delete a ${row.name}. This Action cannot be undone.`,
    };
  };

  const { label, icon, buttonLabel } = confirmationInfo(company);

  const onAction = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onUpdate = async () => {
    mutate(
      new Company({
        ...company,
        isCustomer: !company.isCompany(),
        active: !company.active,
      }),
    );
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <BasicDetails
        title={`${company.name}`}
        description={<Status />}
        navigateLabel={t`Back to Companies`}
        onNavigateBack={goToCompanies}
      />
      {isOpenModal && (
        <ConfirmationModal
          icon={icon}
          onConfirm={company.canDelete ? mutateOnDelete : onUpdate}
          setShowModal={onAction}
          message={label}
        />
      )}
      <InfoList
        panels={PANELS}
        actions={
          <>
            <LinkButton
              icon="edit"
              iconPlacement="left"
              variant="secondary"
              to={`/manage/companies/${companyId}/edit/info`}
            >
              {t`Edit Company Info`}
            </LinkButton>
            <Button
              icon={icon}
              iconPlacement="left"
              variant="secondary"
              onPress={onAction}
            >
              {buttonLabel}
            </Button>
          </>
        }
      />
    </>
  );
}

export const companyTabViews = () => ({
  label: t`Companies`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "licenses", label: t`Licenses` },
    { id: "users", label: t`Users` },
  ],
});

Component.displayName = "ManageCompanyInfoView";
