import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { companyUserViews } from "./tabs/view/info-view";

export const COMPANY_USER_ROUTES = [
  {
    index: true,
    element: <Navigate to="active" replace />,
  },
  {
    path: "active",
    lazy: () => import("./user-list"),
  },
  {
    path: "pending",
    lazy: () => import("./user-list"),
  },
  {
    path: "deactive",
    lazy: () => import("./user-list"),
  },
  {
    path: ":userId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={companyUserViews} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "roles",
        lazy: () => import("./tabs/view/roles-view"),
      },
      {
        path: "groups",
        lazy: () => import("./tabs/view/groups-view"),
      },
      {
        path: "device-rights",
        lazy: () => import("./tabs/view/device-rights-view"),
      },
    ],
  },
  {
    path: ":userId/edit*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/edit/info-edit"),
      },
      {
        path: "roles",
        lazy: () => import("./tabs/edit/roles-edit"),
      },
      {
        path: "groups",
        lazy: () => import("./tabs/edit/groups-edit"),
      },
      {
        path: "device-rights",
        lazy: () => import("./tabs/edit/device-rights-edit"),
      },
    ],
  },
];
