import React from "react";
import { Outlet } from "react-router-dom";

import { useMeQuery } from "../queries/me.queries";
import CompanyNavBar from "../company-navbar";

export function CompanyProtectedRoute() {
  const { isFetched } = useMeQuery();
  if (!isFetched) return null;

  return (
    <div>
      <CompanyNavBar />
      <Outlet />
    </div>
  );
}
