import React from "react";
import { Outlet } from "react-router-dom";

import { useMeQuery } from "../queries/me.queries";
import ManageNavbar from "../nav-bar/manage-nav-bar";

export function ProtectedRoute() {
  const { isFetched } = useMeQuery();
  if (!isFetched) return null;

  return (
    <div>
      <ManageNavbar />
      <Outlet />
    </div>
  );
}
