import React from "react";
import { t } from "@lingui/macro";
import { useParams } from "react-router-dom";

import UserInfoView from "../../../common/user-view";
import { CompanyUserService } from "../../../services/company-user-service";
import { CompanyInviteService } from "../../../services/company-invite-service";

export function Component() {
  const { userId, companyId } = useParams();
  const userService = new CompanyUserService(+companyId);
  const inviteService: CompanyInviteService = new CompanyInviteService(
    +companyId,
  );

  return (
    <UserInfoView
      id={userId}
      userService={userService}
      redirectUrl={`/${companyId}/users`}
      deleteMessage={(email) =>
        t`You're about to remove ${email} from this company. This action cannot be undone.`
      }
      inviteService={inviteService}
    />
  );
}

export const companyUserViews = () => {
  return {
    label: t`Users`,
    navList: [
      { id: "info", label: t`Info` },
      { id: "roles", label: t`Roles` },
      { id: "groups", label: t`Groups` },
      { id: "device-rights", label: t`Device Rights` },
    ],
  };
};

Component.displayName = "CompanyUserInfoView";
