import { queryOptions, useQuery } from "@tanstack/react-query";
import { MeService } from "../services/me-service";

export const meQueries = {
  details: queryOptions({
    queryKey: ["get-me"],
    queryFn: () => MeService.get(),
  }),
};

export function useMeQuery() {
  return useQuery(meQueries.details);
}
