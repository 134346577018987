import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { License } from "../dtos/license.model";
import { EntityService } from "./entity-service";

interface IGetAllParams {
  page: number;
  pageSize: number;
  isCustomer: boolean;
  companyId: number;
  filter?: string;
  nameExact?: boolean;
}

export class LicenseService extends EntityService<License> {
  getAll(queries: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<License[]>>(`manage/licenses`, {
        params: { ...queries },
      })
      .then((o) => {
        return {
          ...o.data,
          data: o.data.data.map((license) => new License(license)),
        };
      });
  }

  getOne(id: number, filters?: IGetAllParams) {
    return apiRequest
      .get<ApiResponse<License>>(`manage/licenses/${id}`, {
        params: { ...filters },
      })
      .then((o) => new License(o.data.data));
  }

  create(dto: License) {
    return apiRequest
      .post<ApiResponse<License>>(`manage/licenses`, { ...new License(dto) })
      .then((response) => {
        return response.data.data;
      });
  }

  update(id: number, dto: License) {
    return apiRequest
      .put<ApiResponse<License>>(`manage/licenses/${id}`, {
        ...new License(dto),
      })
      .then((o) => new License(o.data.data));
  }

  delete(id: number) {
    return apiRequest.delete(`manage/licenses/${id}`);
  }
}
