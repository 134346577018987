import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { NavigationBar, Stack, Text } from "@fidelix/fx-miranda";

import { EntityService } from "../services/entity-service";
import {
  AuditLogEntity,
  IActiveSection,
  ActiveSection,
  isPathIncluded,
} from "../helper";
import { Entity } from "../dtos/entity.model";
import Breadcrumb from "../breadcrumbs/breadcrumb";

type NavItem = {
  id: string;
  label: string;
};

export type getNavInfo = () => void | {
  label?: string;
  navList: NavItem[];
};

const WithSideNavi = (
  Component: React.FC<IActiveSection>,
  getNavInfo: getNavInfo = () => {},
) => {
  const MyComp = (
    prop: Record<
      string,
      string | number | JSX.Element | boolean | EntityService<Entity>
    >,
  ) => {
    const params = useParams();
    const activeSection = params["*"] as AuditLogEntity | ActiveSection;
    const navigate = useNavigate();
    const { label, navList } = getNavInfo() || { label: null, navList: [] };
    const isRouteExists = navList.length > 0;
    const breadcrumbPaths = ["/edit", "/new"];

    const handleSideNavigation = (tab: string) => {
      navigate(window.location.pathname.replace(params["*"], tab));
    };

    return (
      <Stack>
        <SideNavContainer axis="y" isRouteExists={isRouteExists}>
          {isRouteExists && (
            <>
              {label && <InfoTitle variant={"title2Bold"}>{label}</InfoTitle>}
              <NavigationBar>
                {navList.map((navItem: { id: string; label: string }) => (
                  <NavigationBar.Item
                    key={navItem.id}
                    label={navItem.label}
                    isActive={params["*"] === navItem.id}
                    onPress={() => handleSideNavigation(navItem.id)}
                  />
                ))}
              </NavigationBar>
            </>
          )}
        </SideNavContainer>

        <BreadcrumbWrapper axis="y" isRouteExists={isRouteExists}>
          {breadcrumbPaths.some(isPathIncluded) && <Breadcrumb />}

          <ComponentWrapper>
            <Component
              {...prop}
              activeSection={isRouteExists ? activeSection : undefined}
            />
          </ComponentWrapper>
        </BreadcrumbWrapper>
      </Stack>
    );
  };
  return MyComp;
};

export default WithSideNavi;

const BreadcrumbWrapper = styled(Stack)<{ isRouteExists: boolean }>`
  padding-top: ${({ isRouteExists, theme }) =>
    isRouteExists ? 0 : theme.spacing.large}px;
  width: 100%;
  margin-right: ${({ isRouteExists, theme }) =>
    isRouteExists ? theme.spacing.xxsmall : theme.spacing.xsmall}%;
`;

const ComponentWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.large}px;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xxsmall}%;
`;

const InfoTitle = styled(Text)`
  margin: ${(p) => p.theme.spacing.none}px ${(p) => p.theme.spacing.none}px
    ${(p) => p.theme.spacing.normal}px ${(p) => p.theme.spacing.normal}px;
`;

const SideNavContainer = styled(Stack)<{ isRouteExists: boolean }>`
  width: ${({ isRouteExists }) => (isRouteExists ? "20%" : "15%")};
  margin: ${(p) => p.theme.spacing.large}px ${(p) => p.theme.spacing.none}px
    ${(p) => p.theme.spacing.none}px ${(p) => p.theme.spacing.normal}px;
`;
