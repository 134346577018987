import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { roleTabViews } from "./tabs/view/info-view";

export const COMPANY_ROLE_ROUTES = [
  { index: true, element: <Navigate to="active" replace /> },
  {
    path: "active",
    lazy: () => import("./roles-list"),
  },
  {
    path: "denied",
    lazy: () => import("./roles-list"),
  },
  {
    path: "new",
    lazy: () => import("./tabs/create"),
  },
  {
    path: ":roleId/*",
    element: <WithSideNaviWrapper component={Outlet} navInfo={roleTabViews} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: () => import("./tabs/view/info-view"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/view/permissions-view"),
      },
      {
        path: "users",
        lazy: () => import("./tabs/view/users-view"),
      },
    ],
  },
  {
    path: ":roleId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        path: "info",
        lazy: () => import("./tabs/edit/info-edit"),
      },
      {
        path: "permissions",
        lazy: () => import("./tabs/edit/permissions-edit"),
      },
      {
        path: "users",
        lazy: () => import("./tabs/edit/users-edit"),
      },
    ],
  },
];
