import React from "react";
import { t } from "@lingui/macro";
import { Outlet } from "react-router-dom";
import { TopNavigation } from "@fidelix/fx-miranda";

import { usePathNavigator } from "../auth/auth.utils";
import { useMeQuery } from "../queries/me.queries";
import ProfileMenu from "../profile-menu/profile-menu";

export function AccountsProtectedRoute() {
  const { withSubUrl } = usePathNavigator();
  const { isFetched, data: currentUser } = useMeQuery();
  if (!isFetched) return null;

  const menuItems = [
    {
      to: withSubUrl("https://analytics2.fidelix.com"),
      label: t`SCADA`,
    },
    {
      to: withSubUrl("https://assets.fidelix.com"),
      label: t`Device Management`,
    },
    {
      to: withSubUrl("https://admin.fidelix.com"),
      label: t`Access Management`,
    },
  ];

  return (
    <div>
      <TopNavigation variant="primary">
        <TopNavigation.Logo menuItems={menuItems} />
        <TopNavigation.Actions>
          <ProfileMenu name={currentUser?.fullName || ""} id="Member" />
        </TopNavigation.Actions>
      </TopNavigation>{" "}
      <Outlet />
    </div>
  );
}
