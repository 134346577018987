import { t } from "@lingui/macro";

import { AUDIT_LOG_KEYS, Permission } from "./constant";
import { IColumnOption } from "./common/table";
import { Entity } from "./dtos/entity.model";

export type ActiveSection = "active" | "denied" | "deactive" | "pending";

export type AuditLogEntity = "user" | "role" | "license" | "apiKey";

export type IActiveSection = {
  activeSection?: ActiveSection | AuditLogEntity;
};
interface IDetailProps {
  id: number;
  name: string;
}

interface IAuditLogProps {
  key: keyof typeof AUDIT_LOG_KEYS;
  old?: string;
  new?: string;
}

interface IAuditAssignProps extends IAuditLogProps {
  added?: IDetailProps;
  removed?: IDetailProps;
}

interface IAuditUpdateProps extends IAuditLogProps {
  added?: IDetailProps[];
  removed?: IDetailProps[];
}

export interface GenericRecord {
  [key: string]: GenericDataType;
}

export type GenericDataType =
  | string
  | string[]
  | number
  | number[]
  | GenericRecord
  | GenericRecord[];

declare global {
  interface Array<T> {
    toggle(item: T): T[];
  }
}

Array.prototype.toggle = function (element) {
  const index = this.indexOf(element);
  if (index !== -1) {
    this.splice(index, 1);
  } else {
    this.push(element);
  }
  return this;
};

export const isEmail = (value: string) =>
  value && /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BASIC_COLUMNS: () => IColumnOption<any>[] = () => [
  {
    label: t`Name`,
    accessor: "name",
    isSortable: true,
  },
  {
    label: t`Description`,
    accessor: "description",
  },
];

export const PAGE_SIZE = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
];

export const formattedDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  return date.toLocaleString(window.navigator.language, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getUpdateTemplate = (entity: string, props: IAuditUpdateProps) => {
  const { old, added, removed, key } = props;
  const auditKey = AUDIT_LOG_KEYS[key];
  if (key === "active") {
    return props.new
      ? t`The ${entity} was activated`
      : t`The ${entity} was deactivated`;
  } else if (old !== undefined && props.new !== undefined) {
    return t`The ${entity}'s ${auditKey} was changed from ${old} to ${props.new}`;
  } else if (added) {
    const addedItems = added.map((item) => item.name).join(", ");
    return t`The following ${auditKey} were added: ${addedItems}`;
  } else if (removed) {
    const removedItems = removed.map((item) => item.name).join(", ");
    return t`The following ${auditKey} were removed: ${removedItems}`;
  }
  return "";
};

const getAssignTemplate = (entity: string, props: IAuditAssignProps) => {
  if (props.added) {
    return t`The  ${[entity]} was assigned to the following ${props.key}: ${props.added.name} `;
  } else if (props.removed) {
    return t`The  ${[entity]} was removed from the following ${props.key}: ${props.removed.name} `;
  }
  return "";
};

export const getTemplate = (
  action: string,
  entity: keyof typeof AUDIT_LOG_KEYS,
  props: IAuditUpdateProps | IAuditAssignProps,
) => {
  const auditEntity = AUDIT_LOG_KEYS[entity];
  switch (action) {
    case "create":
      return t`The ${auditEntity} was created`;
    case "delete":
      return t`The ${auditEntity} was deleted`;
    case "reinvite":
      return t`The ${auditEntity} was re-invited`;
    case "accept":
      return t`The ${auditEntity} accepted the invitation`;
    case "invite":
      return t`The ${auditEntity} was invited`;
    case "assign":
      return getAssignTemplate(auditEntity, props as IAuditAssignProps);
    case "update":
      return getUpdateTemplate(auditEntity, props as IAuditUpdateProps);
    case "login":
      return t`A new login was created for the user`;
    default:
      return "";
  }
};

export const upperLowerCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getPaths = () => {
  return window.location.pathname.split("/").filter(Boolean);
};

export const isPathIncluded = (str: string) => {
  return window.location.pathname.includes(str);
};

export const isExpire = (expiredAt: Date) => {
  const currentDate = new Date().toISOString();
  return new Date(expiredAt) < new Date(currentDate);
};

export const getDetails = (
  details: { name: string }[],
  type = "roles",
): string => {
  if (details) {
    if (details.length === 1) {
      return details[0].name;
    } else if (details.length >= 1) {
      return t`Multiple ${type} (${details.length})`;
    }
  }
  return "";
};

/**
 * @param e synthatic event on click
 */
export const preventDefault = (e: React.SyntheticEvent) => {
  e.preventDefault();
};

export const hasPermission = (
  permissions: string[] = [],
  permission: Permission,
): boolean => {
  return (permissions || []).includes(permission);
};

/**
 *
 * @param data is array of ids
 * @returns will returns the array of objects containg ids
 * @example
 *    data = [1, 2, 3];
 *    returned value = [{ id: 1 }, { id: 2 }, ...];
 */
export const mapIdsToObjId = <T extends Entity>(data: number[]) => {
  return data.map((val) => ({ id: +val })) as T[];
};
