import React from "react";
import { t } from "@lingui/macro";
import {
  Button,
  Icon,
  IconName,
  Modal,
  Stack,
  Text,
} from "@fidelix/fx-miranda";
import { styled } from "styled-components";

interface IProps {
  setShowModal: (action: boolean) => void;
  onConfirm: () => void;
  message: string;
  icon: IconName;
}
const ConfirmationModal = ({
  setShowModal,
  onConfirm,
  message,
  icon,
}: IProps) => {
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Modal placement="middle" onClose={onClose}>
      <ModalBody>
        <Stack axis="y" spacing="normal" align="center">
          <Icon icon={icon} size={67} color="primary" />
          <Text variant="title2Bold">{t`Are You Sure?`}</Text>
          <Text variant="body">{message}</Text>
        </Stack>
      </ModalBody>

      <Modal.Footer>
        <Stack spacing="normal" justify="center">
          <Button
            variant="secondary"
            data-test-id={"cancel-button"}
            onPress={onClose}
          >
            {t`Cancel`}
          </Button>
          <Button onPress={onConfirm} data-test-id={"confirm-button"}>
            {t`Continue`}
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;

const ModalBody = styled(Modal.Body)`
  padding: 48px;
`;
