import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";

import { config } from "../config";
import {
  getAccessToken,
  getCompanyIdForToken,
  getRefreshToken,
  redirectToLogin,
  saveToken,
} from "./auth.utils";

interface CustomConfig extends AxiosRequestConfig {
  headers: Record<string, string>;
}

export class Http {
  protected _axios: AxiosInstance;
  private _subscribers: ((token: string | undefined) => void)[] = [];
  private _refreshing = false;

  constructor(baseUrl: string) {
    this._axios = this.createClient(baseUrl);
    this.addInterceptors();
  }

  createClient(url: string | undefined = undefined) {
    return axios.create({
      baseURL: url,
    });
  }

  addInterceptors() {
    this.addBearerTokenInterceptor();
    this.addUnauthorisedInterceptor();
  }

  addBearerTokenInterceptor() {
    this._axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = getAccessToken();
      return {
        ...config,
        headers: {
          authorization: `Bearer ${token}`,
          ...config.headers,
        } as unknown as AxiosRequestHeaders,
      };
    });
  }

  addUnauthorisedInterceptor() {
    this._axios.interceptors.response.use(
      async (response: AxiosResponse) => response,
      async (error: AxiosError) => this.handleAxiosError(error),
    );
  }

  handleAxiosError(error: AxiosError): Promise<AxiosResponse> {
    const config = error?.config as CustomConfig;

    if (error?.response?.status === 403 || error?.response?.status === 401) {
      if (!this._refreshing) {
        this._refreshing = true;
        this.refreshAccessToken().then(
          (accessToken: string | undefined) => {
            this._refreshing = false;
            this.onRefreshed(accessToken);
          },
          () => {
            // Refreshing token failed, last option is to redirect user to login
            this.handleAuthError(401);
          },
        );
      }
      return this.retryRequest(config);
    } else {
      return Promise.reject(error?.response?.data);
    }
  }

  public onRefreshed(token: string | undefined) {
    this._subscribers.forEach((cb: (token: string | undefined) => void) => {
      return cb(token);
    });
    this._subscribers = [];
  }

  async refreshAccessToken(): Promise<string> {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      return Promise.reject("No refresh token");
    }

    /**
     * If we are under the "member" part of the app we need to pass the companyId
     * to the refresh token endpoint so that the new token is scoped to the company.
     */
    const companyId = getCompanyIdForToken();

    const { data: token } = await this.createClient().get(
      `${config.API_URL}/authenticate`,
      { params: { refreshToken, companyId } },
    );
    saveToken(token);

    return token.access_token;
  }

  retryRequest(config: CustomConfig): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      this.subscribeTokenRefresh((token) => {
        if (!token) {
          reject();
          return;
        }

        config.headers.authorization = `Bearer ${token}`;

        this.retry(config)
          .then((o: AxiosResponse) => resolve(o))
          .catch((err) => {
            this.handleAuthError(err.response.status);
            reject(err);
          });
      });
    });
  }

  subscribeTokenRefresh(cb: (token: string | undefined) => void) {
    this._subscribers.push(cb);
  }

  handleAuthError(errorCode: number) {
    // If you get 403, it means the token is valid but you have no access, thus we can't redirect you to a view that gives you that information.
    if (errorCode === 403) {
      window.location.replace("/auth/unauthorized");
    }

    // If you get 401, it means the access token is somehow not valid even after refresh. I assume the most common reason is that your refresh token is also expired, so then we redirect user to login
    if (errorCode === 401) {
      const { pathname, search } = window.location;
      const redirectUrl = `${pathname}${search}`;
      redirectToLogin(redirectUrl);
    }
  }

  retry(config: CustomConfig) {
    return axios(config);
  }

  public delete(resource: string) {
    return this._axios.delete(resource);
  }

  public put<T>(resource: string, data: unknown) {
    return this._axios.put<T>(resource, data);
  }

  public post<T>(resource: string, data: unknown) {
    return this._axios.post<T>(resource, data);
  }

  public get<T>(
    resource: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this._axios.get<T>(resource, config);
  }
}
