import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { User } from "../dtos/user.model";

export class MeService {
  static get() {
    return apiRequest
      .get<ApiResponse<User>>(`me`)
      .then((o) => new User(o.data.data));
  }

  static update(data: User) {
    return apiRequest
      .put<ApiResponse<User>>(`me`, data)
      .then((o) => new User(o.data.data));
  }
}
