import React, { useState } from "react";
import { Button, LinkButton } from "@fidelix/fx-miranda";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import { useMutation, useQuery } from "@tanstack/react-query";
import InfoList from "../../../common/info-list";
import { Role } from "../../../dtos/role.model";
import { formattedDate } from "../../../helper";
import { RoleService } from "../../../services/role-service";
import { EntityService } from "../../../services/entity-service";
import useToastManager from "../../../hooks/toast-manager";
import ConfirmationModal from "../../../common/confirmation-modal";
import BasicDetails from "../../../common/basic-details";

export function Component() {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { successToast, errorToast } = useToastManager();
  const roleService: EntityService<Role> = new RoleService();

  const { data: role = {} as Role } = useQuery({
    queryKey: ["get-role", roleId],
    queryFn: () => roleService.getOne(+roleId),
  });

  const goToRoles = () => {
    navigate("/manage/roles");
  };

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => roleService.delete(+roleId),
    onSuccess: () => {
      successToast(t`Role was deleted successfully.`);
      navigate(`/manage/roles`);
    },
    onError: () => {
      errorToast(t`Failed to delete role.`);
    },
  });

  const onDelete = () => {
    setIsOpenModal(!isOpenModal);
  };

  const PANELS = [
    {
      header: t`Role Info`,
      rows: [
        {
          label: t`Name`,
          value: role.name,
        },
        {
          label: t`Description`,
          value: role?.description,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Created`,
          value: !role.isSystem
            ? role.createdBy + ", " + formattedDate(role.createdAt)
            : "",
        },
        {
          label: t`Updated`,
          value: !role.isSystem
            ? role.updatedBy + ", " + formattedDate(role.updatedAt)
            : "",
        },
      ],
    },
  ];

  return (
    <>
      {isOpenModal && (
        <ConfirmationModal
          icon="trash"
          onConfirm={mutateOnDelete}
          setShowModal={onDelete}
          message={`You're about to delete a ${role.name}. This Action cannot be undone.`}
        />
      )}
      <BasicDetails
        title={role.name}
        description={role.description}
        navigateLabel={t`Back to Roles`}
        onNavigateBack={goToRoles}
      />
      <InfoList
        panels={PANELS}
        actions={
          !role.isSystem ? (
            <>
              {role.canEdit && (
                <LinkButton
                  icon="edit"
                  iconPlacement="left"
                  variant="secondary"
                  to={`/manage/roles/${roleId}/edit/info`}
                >
                  {t`Edit Role Info`}
                </LinkButton>
              )}
              {role.canDelete && (
                <Button
                  icon="trash"
                  iconPlacement="left"
                  variant="secondary"
                  onPress={onDelete}
                  isDisabled={!role.canDelete}
                >
                  {t`Delete Role`}
                </Button>
              )}
            </>
          ) : undefined
        }
      />
    </>
  );
}

export const roleTabViews = () => ({
  label: t`Roles`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "permissions", label: t`Permissions` },
  ],
});

Component.displayName = "RoleInfoView";
