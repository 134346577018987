import { matchPath } from "react-router-dom";
import CryptoJS from "crypto-js";

import { config } from "../config";

/**
 * Microsoft identity platform and OAuth 2.0 authorization code flow:
 * https://learn.microsoft.com/en-us/entra/identity-platform/v2-oauth2-auth-code-flow
 *
 * The auth flow is handled in the following way:
 *
 * 1. Detect that the user is not authenticated.
 *    - There is no need to manually check whether the user is authenticated or
 *      not! We just try to fetch data and then handle the potential auth error.
 *    - `Http` service does this by checking if the response status is 401 or 403.
 * 2. Redirect the user to the login page.
 *    - Define a callback URL (`/auth/callback`) that the user is redirected to
 *      after logging in.
 *    - Pass the current page as a `state` query param so that we can redirect
 *      the user back to the page they were on before logging in.
 *    - Optionally pass the company ID as a query param if we are under the
 *      "member" part of the app so that we can tie the token to that company.
 *    - The company ID is read from the URL path params (`/:companyId/*`).
 * 3. The user logs in and is redirected back to the callback URL.
 *   - The callback URL is handled by the `AuthCallback` component.
 *   - Fetch the auth token using /authenticate api of admin with the authorization code from the query param.
 *   - Save the token in the session storage (access token and refresh token).
 *   - Create a user in our system if it's the first time the user logs in.
 * 4. Redirect the user back to the page they were on before logging in.
 *   - Read the `state` query param to get the redirect URL.
 *   - If the auth flow was initiated by another app (assets/analytics) we append
 *     the `refreshToken` as query params to the redirect url and
 *     redirect to it so that the other app can finalize the auth flow by fetching
 *     the token etc.
 *   - If the auth flow was initiated by the admin app user we redirect them to
 *     the page they were on before logging in.
 * 5. (Optional) Refresh the token.
 *   - If there was no company ID available when the token was fetched and the user
 *     is NOT a super admin we need to refresh the token with the company ID.
 *   - This happens by first fetching the users companies and using the first
 *     company as the selected company. The user is navigated to whatever is the
 *     home page for that selected company and then when that page tries to fetch
 *     data the first call will fail and the `Http` service will automatically
 *     refresh the token with the company ID and retry the request. From that point
 *     on the token is tied to the company.
 */

export interface IToken {
  access_token: string;
  expires_in: number;
  expires_on: number;
  id_token: string;
  id_token_expires_in: number;
  not_before: number;
  profile_info: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  resource: string;
  scope: string;
  token_type: "Bearer";
}

const accessTokenKey = "fxfi-access-token";
const refreshTokenKey = "fxfi-refresh-token";
export const codeVerifierKey = "fxfi-code-verifier";

const generateCodeVerifier = () => {
  return CryptoJS.lib.WordArray.random(32).toString();
};

const generateCodeChallenge = (codeVerifier: string) => {
  const sha256 = (text: string) =>
    CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(text));
  return sha256(codeVerifier);
};

export function getAccessToken() {
  return sessionStorage.getItem(accessTokenKey);
}

export function getRefreshToken() {
  return sessionStorage.getItem(refreshTokenKey);
}

export function saveToken(token: IToken) {
  sessionStorage.setItem(accessTokenKey, token.access_token);
  sessionStorage.setItem(refreshTokenKey, token.refresh_token);
}

export function removeSession() {
  sessionStorage.removeItem(accessTokenKey);
  sessionStorage.removeItem(refreshTokenKey);
}

/**
 * Check if a URL is a valid external redirect URL, meaning it's a URL that
 * we trust to redirect to, eg. the assets or analytics app.
 */

export function isValidExternalRedirectUrl(redirectUrl: string) {
  const domains = config.ALLOWED_DOMAINS;
  if (!domains) {
    return false;
  }
  try {
    const url = new URL(redirectUrl);
    const validDomains = domains
      .split(",")
      .map((domain) => domain.trim().toLowerCase());
    return validDomains.some((domain) => {
      return url.host.toLowerCase() === domain;
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return false;
  }
}

export function redirectToLogout(redirectUrl = config.ADMIN_URL) {
  removeSession();
  const logoutUrl = new URL(config.LOGOUT_URL);
  logoutUrl.searchParams.append("post_logout_redirect_uri", redirectUrl);
  window.location.replace(logoutUrl);
}

export function redirectToLogin(redirectUrl: string) {
  /**
   * Pass `redirectUrl` as state so that we get it back in the auth callback route.
   * We use it to redirect the user back to the page they were on before logging in.
   */
  const state = JSON.stringify({ redirectUrl });
  const adminUrl = new URL(config.ADMIN_URL);
  const callbackUrl = `${adminUrl.origin}/auth/callback`;
  const loginUrl = new URL(config.LOGIN_URL);

  const codeVerifier = generateCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);

  sessionStorage.setItem(codeVerifierKey, codeVerifier);
  const loginParams = {
    state,
    nonce: "defaultNonce",
    redirect_uri: callbackUrl,
    response_type: "code",
    code_challenge_method: "S256",
    code_challenge: encodeURIComponent(codeChallenge),
  };

  Object.entries(loginParams).forEach(([key, value]) => {
    loginUrl.searchParams.append(key, value);
  });
  window.location.replace(loginUrl);
}

/**
 * Read the current company ID from the URL if the user is under the "member"
 * part of the app.
 */
export function getCompanyIdForToken() {
  const match = matchPath("/:companyId/*", window.location.pathname);

  if (!match || !match.params.companyId) {
    return null;
  }

  const companyId = Number(match.params.companyId);

  if (isNaN(companyId)) {
    return null;
  }

  return companyId;
}

const parseBaseUrl = (url: string): string | null => {
  const match = url.match(/^(.*?)-/);
  return match ? match[0] : null;
};

const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export function usePathNavigator() {
  const baseUrl = parseBaseUrl(window.location.host);
  const withSubUrl = (path: string): string => {
    if (!baseUrl || !isValidURL(path)) {
      return path;
    }
    return constructNewUrl(path, baseUrl);
  };
  return { withSubUrl };
}

function constructNewUrl(path: string, baseUrl: string | null) {
  const url = new URL(path);
  const [hostnameParts, ...rest] = url.hostname.split(".");
  const updatedHost = `${baseUrl}${hostnameParts}`;
  url.hostname = [updatedHost, ...rest].join(".");
  if (url.toString().includes(window.location.host)) {
    return "/";
  }
  return url.toString();
}
