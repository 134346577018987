import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, IconName, LinkButton } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";

import useToastManager from "../../../hooks/toast-manager";
import { formattedDate } from "../../../helper";
import ConfirmationModal from "../../../common/confirmation-modal";
import { ApiKey } from "../../../dtos/api-key.model";
import InfoList from "../../../common/info-list";
import { ApiKeyService } from "../../../services/api-key-service";
import BasicDetails from "../../../common/basic-details";
import StatusBadge from "../../../common/status-badge";

interface IConfirmationInfo {
  label: string;
  icon: IconName;
  buttonLabel: string;
}

export function Component() {
  const navigate = useNavigate();
  const { companyId, apiKeyId } = useParams();
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToastManager();
  const keyService = new ApiKeyService(+companyId);
  const [showModal, setShowModal] = useState<string | null>(null);

  const { data: apiKey = {} as ApiKey } = useQuery({
    queryKey: ["get-api-key", apiKeyId],
    queryFn: () => keyService.getOne(+apiKeyId),
  });

  const handleModalToggle = (modalType?: string | null) => {
    setShowModal((prev) => (prev === modalType ? null : modalType || null));
  };

  const { mutate } = useMutation({
    mutationFn: (data: ApiKey) => keyService.update(apiKey.id, data),
    onSuccess: (key) => {
      successToast(
        t`API key ${key.active ? "activated" : "deactivated"} successfully.`,
      );
      queryClient.invalidateQueries({ queryKey: ["get-api-key"] });
      handleModalToggle(null);
    },
    onError: () => {
      errorToast(
        t`Failed to ${apiKey.active ? "deactivate" : "activate"} key.`,
      );
    },
  });

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => keyService.delete(+apiKey.id),
    onSuccess: () => {
      successToast(t`API key was deleted successfully.`);
      navigate(`/${companyId}/api-keys`);
    },
    onError: () => errorToast(t`Failed to delete API key.`),
  });

  const PANELS = [
    {
      header: t`API Key Info`,
      rows: [
        {
          label: t`Key`,
          value: apiKey.key,
        },
        {
          label: t`Description`,
          value: apiKey.description,
        },
      ],
    },
    {
      rows: [
        {
          label: t`Created`,
          value: apiKey.createdBy + ", " + formattedDate(apiKey.createdAt),
        },
        {
          label: t`Updated`,
          value: apiKey.updatedBy + ", " + formattedDate(apiKey.updatedAt),
        },
      ],
    },
  ];

  const confirmationInfo = (row: ApiKey): IConfirmationInfo => {
    if (row.active) {
      return {
        icon: "deactive",
        buttonLabel: t`Deactivate API key`,
        label: t`When you deactivate an API key, the premissions granted to the key no longer valid`,
      };
    }
    return {
      icon: "plus",
      buttonLabel: t`Activate API key`,
      label: t`When you reactivate the API key, the key's permissions come into effect again.`,
    };
  };

  const { label, icon, buttonLabel } = confirmationInfo(apiKey);

  const onDelete = () => {
    handleModalToggle("delete");
  };

  const onDeactivate = () => {
    handleModalToggle("deactivate");
  };

  const onUpdate = async () => {
    mutate(
      new ApiKey({
        ...apiKey,
        active: !apiKey.active,
      }),
    );
  };

  return (
    <>
      {showModal && (
        <ConfirmationModal
          icon={showModal === "delete" ? "trash" : icon}
          onConfirm={showModal === "delete" ? mutateOnDelete : onUpdate}
          setShowModal={() => handleModalToggle(null)}
          message={
            showModal === "delete"
              ? t`You're about to delete a ${apiKey.description}. This Action cannot be undone.`
              : label
          }
        />
      )}

      <BasicDetails
        title={`${apiKey.description}`}
        description={<StatusBadge active={apiKey.active} />}
        navigateLabel={t`Back to API keys`}
        onNavigateBack={() => navigate(`/${companyId}/api-keys`)}
      />
      <InfoList
        panels={PANELS}
        actions={
          <>
            <LinkButton
              icon="edit"
              iconPlacement="left"
              variant="secondary"
              to={`/${companyId}/api-keys/${apiKeyId}/edit/info`}
            >
              {t`Edit API Key Info`}
            </LinkButton>
            <Button
              icon={icon}
              iconPlacement="left"
              variant="secondary"
              onPress={onDeactivate}
            >
              {buttonLabel}
            </Button>
            <Button
              icon="trash"
              iconPlacement="left"
              variant="secondary"
              onPress={onDelete}
            >
              {t`Delete API key`}
            </Button>
          </>
        }
      />
    </>
  );
}

export const apiKeyViewTabs = () => ({
  label: t`API Keys`,
  navList: [
    { id: "info", label: t`Info` },
    { id: "permissions", label: t`Permissions` },
    { id: "device-rights", label: t`Device Rights` },
  ],
});

Component.displayName = "KeyInfoView";
