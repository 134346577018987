import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import WithSideNavi from "../hoc/with-side-navi";
import WithSideNaviWrapper from "../router/with-side-navi-wrapper";
import { customersViews } from "./tabs/view/info-view";
import { userTabViews } from "./tabs/view/user-view";

export const COMPANY_CUSTOMER = [
  {
    index: true,
    element: <Navigate to="active" replace />,
  },
  {
    path: "active",
    lazy: () => import("./customer-list"),
  },
  {
    path: "deactive",
    lazy: () => import("./customer-list"),
  },
  {
    path: "new",
    lazy: async () =>
      import("./tabs/customer-create").then(({ Component }) => ({
        Component: WithSideNavi(Component),
      })),
  },
  {
    path: ":customerId/*",
    element: (
      <WithSideNaviWrapper component={Outlet} navInfo={customersViews} />
    ),
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("./tabs/view/info-view"),
      },
      {
        path: "licenses",
        lazy: async () => import("./tabs/view/licenses-view"),
      },
      {
        path: "groups",
        lazy: async () => import("./tabs/view/groups-view"),
      },
      {
        path: "users",
        lazy: async () => import("./tabs/view/users-list"),
      },
    ],
  },
  {
    path: ":customerId/users/:userId/*",
    element: <WithSideNaviWrapper component={Outlet} navInfo={userTabViews} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("./tabs/view/user-view"),
      },
      {
        path: "roles",
        lazy: async () => import("../companies/tabs/view/user-roles-view"),
      },
    ],
  },
  {
    path: ":customerId/users/:userId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        index: true,
        element: <Navigate to="info" replace />,
      },
      {
        path: "info",
        lazy: async () => import("../companies/tabs/edit/user-edit"),
      },
      {
        path: "roles",
        lazy: async () => import("../companies/tabs/edit/user-roles-edit"),
      },
    ],
  },
  {
    path: ":customerId/edit/*",
    element: <WithSideNaviWrapper component={Outlet} />,
    children: [
      {
        path: "info",
        lazy: async () => import("./tabs/edit/info-edit"),
      },
      {
        path: "licenses",
        lazy: async () => import("./tabs/edit/licenses-edit"),
      },
      {
        path: "groups",
        lazy: async () => import("./tabs/edit/groups-edit"),
      },
    ],
  },
];
