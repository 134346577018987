import { apiRequest } from "../auth/api-request";
import { ApiResponse } from "../dtos/api-respone.model";
import { Company } from "../dtos/company.model";

export class InternalCompaniesService {
  static getAll() {
    return apiRequest
      .get<ApiResponse<Company[]>>(`api/internal/companies`, {
        params: { permission: "company.admin" },
      })
      .then((o) => o.data.data);
  }
}
