import React, { useState } from "react";
import { t } from "@lingui/macro";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, IconName, LinkButton } from "@fidelix/fx-miranda";
import { useNavigate, useParams } from "react-router-dom";

import { User } from "../dtos/user.model";
import MultiStatusBadge from "../common/multi-status-badge";
import { EntityService } from "../services/entity-service";
import InfoList from "../common/info-list";
import BasicDetails from "../common/basic-details";
import useToastManager from "../hooks/toast-manager";
import { formattedDate } from "../helper";
import ConfirmationModal from "../common/confirmation-modal";
import { Invite } from "../dtos/invite.model";
import Loader from "./loader";

interface IProps {
  userService: EntityService<User>;
  id: string;
  redirectUrl: string;
  deleteMessage: (email: string) => string;
  inviteService: EntityService<Invite>;
}

interface IConfirmationInfo {
  label: string;
  icon: IconName;
  buttonLabel: string;
  dataTestId?: string;
}

const UserInfoView = ({
  id,
  userService,
  redirectUrl,
  deleteMessage,
  inviteService,
}: IProps) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { successToast, errorToast } = useToastManager();
  const [modal, setModal] = useState<JSX.Element | undefined>(undefined);
  const queryClient = useQueryClient();

  const { data: user = {} as User, isLoading } = useQuery({
    queryKey: ["get-user", id],
    queryFn: () => userService.getOne(+id),
  });

  const PANELS = [
    {
      header: t`User Info`,
      rows: [
        { label: t`First name`, value: user?.firstName },
        { label: t`Last name`, value: user?.lastName },
        { label: t`Email`, value: user.email },
      ],
    },
    user.status === "Pending"
      ? {
          header: t`Invitation Info`,
          rows: [
            {
              label: t`Invited`,
              value: user.invites
                ? formattedDate(
                    //A user can only have one invite at a time
                    user.invites[0].createdAt,
                  )
                : "-",
            },
            {
              label: t`Invited By`,
              value: user.invites
                ? user.invites
                    .map((invite: Invite) => invite.createdBy)
                    .toString()
                : "-",
            },
            {
              label: t`Validity`,
              value: user.invites
                ? formattedDate(
                    //A user can only have one invite at a time
                    user.invites[0].expiredAt,
                  )
                : "-",
            },
          ],
        }
      : {
          header: t`Log In Info`,
          rows: [
            {
              label: t`Last Log In`,
              value: user.lastLogin ? formattedDate(user.lastLogin) : "-",
            },
            {
              label: t`Registered`,
              value: user.createdAt ? formattedDate(user.createdAt) : "-",
            },
          ],
        },
  ];

  const onDelete = () => {
    setModal(
      <ConfirmationModal
        icon="trash"
        onConfirm={mutateOnDelete}
        setShowModal={() => setModal(undefined)}
        message={deleteMessage(user.email)}
      />,
    );
  };

  const onDeactivate = () => {
    setModal(
      <ConfirmationModal
        icon={icon}
        onConfirm={onUpdate}
        setShowModal={() => setModal(undefined)}
        message={label}
      />,
    );
  };

  const { mutate: mutateOnDelete } = useMutation({
    mutationFn: () => userService.delete(+userId),
    onSuccess: () => {
      successToast(t`User was deleted successfully.`);
      navigate(redirectUrl);
    },
    onError: () => {
      errorToast(t`Failed to delete user.`);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (data: User) => userService.update(+userId, data),
    onSuccess: () => {
      successToast(
        t`User ${user.active ? "deactivated" : "activated"} successfully.`,
      );
      setModal(undefined);
      queryClient.invalidateQueries({ queryKey: ["get-user"] });
    },
    onError: () => errorToast(t`Failed to update user.`),
  });

  const { mutate: mutateOnInvite } = useMutation({
    mutationFn: (data: { email: string }) => inviteService.create(data),
    onSuccess: () => {
      successToast(`${user.email} ${t`reinvited successfully.`}`);
      queryClient.invalidateQueries({ queryKey: ["get-user"] });
    },
    onError: () => {
      errorToast(`${t`Error! Email not sent for`} ${user.email}`);
    },
  });

  const onReinvite = () => mutateOnInvite({ email: user.email });

  const onUpdate = () => {
    mutate(new User({ ...user, active: !user.active }));
  };

  const confirmationInfo = (row: User): IConfirmationInfo => {
    if (user.status === "Pending") {
      return {
        icon: "ticketFilled",
        buttonLabel: t`Reinvite`,
        label: "",
        dataTestId: "reinvite-button",
      };
    }
    if (row.active) {
      return {
        icon: "deactive",
        buttonLabel: t`Deactivate user`,
        label: t`When you deactivate a user, that user can no longer use the roles authorized by the company. You can reactivate the user at any time and enable the use of the services again.`,
        dataTestId: "deactive-button",
      };
    }
    return {
      icon: "plus",
      buttonLabel: t`Activate user`,
      label: t`When you reactivate the user, the user's roles come into effect again and users can use the services authorized by this company.`,
      dataTestId: "plus-button",
    };
  };

  const { label, icon, buttonLabel, dataTestId } = confirmationInfo(user);

  const goToUsers = () => {
    navigate(redirectUrl);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <BasicDetails
        title={`${user.firstName || ""} ${user.lastName || ""}`}
        description={user.email}
        navigateLabel={t`Back to Users`}
        onNavigateBack={goToUsers}
      >
        <>
          {user.status === "Pending" ? (
            <MultiStatusBadge
              status={
                user.invites.some(
                  (invite: Invite) =>
                    new Date(invite.expiredAt).getTime() > new Date().getTime(),
                )
                  ? "Pending"
                  : "Denied"
              }
              label={
                user.invites.some(
                  (invite: Invite) =>
                    new Date(invite.expiredAt).getTime() > new Date().getTime(),
                )
                  ? t`Pending`
                  : t`Expired`
              }
            />
          ) : (
            <MultiStatusBadge status={user.status} />
          )}
        </>
      </BasicDetails>
      {modal}
      <InfoList
        panels={PANELS}
        actions={
          <>
            <LinkButton
              icon="edit"
              iconPlacement="left"
              variant="secondary"
              to={`${redirectUrl}/${id}/edit/info`}
            >
              {t`Edit user Info`}
            </LinkButton>
            <Button
              icon={icon}
              iconPlacement="left"
              data-test-id={dataTestId}
              variant="secondary"
              onPress={user.status === "Pending" ? onReinvite : onDeactivate}
            >
              {buttonLabel}
            </Button>
            <Button
              icon="trash"
              iconPlacement="left"
              variant="secondary"
              onPress={onDelete}
            >
              {t`Remove user`}
            </Button>
          </>
        }
      />
    </>
  );
};

export default UserInfoView;
